import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getMarketingCollaterals,
  listCollateralsGroups,
  deleteCollaterals,
  listClients,
  listAgents,
  getAttachedClients,
  deleteClientDocument,
  getAttachedAgents,
  deleteAgentDocument,
} from "./../../../config/collateral_apis";
import DataTable from "../../../components/Tables/DataTableExtended";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { APP_LIVE_URL } from "./../../../config/config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Collaterals extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      formLoader: true,
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: [],
      document_file_name: "",
      document_for: "none",
      all_collaterals_groups: [],
      group_id: "",
      group_title: "",
      selected_group_id: null,
      tableLoader: true,
      collaterals_id: "",
      errorMessageDelete: "",
      successMessageDelte: "",
      isProcessing: false,
      clientsList: [],
      agentList: [],
      selectedClients: [],
      selectedAgents: [],
      selectAllClient: true,
      selectAllAgent: true,
      selectedTab: "clients",
      client_details: [],
      client_id: "",
      errorMessageDeleteClientDoc: "",
      successMessageDelteClientDoc: "",
      agent_details: [],
      agent_id: "",
      errorMessageDeleteAgentDoc: "",
      successMessageDelteAgentDoc: "",
      columnServices: [
        { name: "TITLE" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "DOWNLOAD",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Group" },
        {
          name: "FOR",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedTo(value, tableMeta)}</div>;
            },
          },
        },
        // { name: "TYPE" },
        // {
        //   name: "STATUS",
        //   options: {
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       return <div>{this.defaultText(value, tableMeta)}</div>;
        //     },
        //   },
        // },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              {/* <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showAssignedServices(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-exchange"></em>
                                  <span>Assign To Agents</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientAgreements(value, tableMeta)
                                  }
                                  title="Client Agreements"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-exchange"></em>
                                  <span>Assign To Clients</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientServices(value, tableMeta)
                                  }
                                  title="Client Notes"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Update Statuses</span>
                                </a>
                              </li>

                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-emails"></em>
                                  <span>Email Collaterals</span>
                                </a>
                              </li> */}
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.deleteCollateral(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Documents</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    this.listClients();
    this.listAgents();
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
    this.listCollateralsGroups();
  }

  listClients = async () => {
    const listAgentsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listClientsResponce: ", listAgentsResponce.data.data);
    if (
      listAgentsResponce.data.status === 403 ||
      listAgentsResponce.data.errors === "authentication missing" ||
      listAgentsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentsResponce.data.status === 200 &&
      listAgentsResponce.data.message === "success"
    ) {
      let clients = listAgentsResponce.data.data.map((client) => ({
        value: client[0],
        label: client[1] + "(" + client[2] + ")",
      }));
      console.log("clientsList clients: ", clients);
      this.setState({
        clientsList: clients,
        selectedClients: clients,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  listAgents = async () => {
    const listAgentsResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listAgentsResponce: ", listAgentsResponce.data.data);
    if (
      listAgentsResponce.data.status === 403 ||
      listAgentsResponce.data.errors === "authentication missing" ||
      listAgentsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentsResponce.data.status === 200 &&
      listAgentsResponce.data.message === "success"
    ) {
      let agents = listAgentsResponce.data.data.map((agent) => ({
        value: agent[8],
        label: agent[1] + "(" + agent[2] + ")",
      }));
      console.log("agentsList agents: ", agents);
      this.setState({
        agentList: agents,
        selectedAgents: agents,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          group_id: changed_value.value,
          group_title: changed_value.label,
          selected_group_id: changed_value,
        }),
        () => {
          console.log(
            "Updated state:",
            this.state.group_id,
            this.state.group_title
          );
        }
      );
    } else {
      this.setState(
        {
          group_id: null,
          group_title: null,
          selected_group_id: null,
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearchBar = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          group_id: changed_value.value,
          group_title: changed_value.label,
          selected_group_id: changed_value,
        }),
        () => {
          console.log(
            "Updated state:",
            this.state.group_id,
            this.state.group_title
          );
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          group_id: null,
          group_title: null,
          selected_group_id: null,
        },
        () => {
          console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken(),
      this.state.group_title
    );
    console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  listCollateralsGroups = async () => {
    const servicesResponce = await listCollateralsGroups(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listCollateralsGroups: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "Groups not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_groups = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][0],
            value: servicesResponce.data.data[i][1],
          };
          all_groups.push(newArr);
        }
      }

      this.setState({
        all_collaterals_groups: all_groups,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while displaying groups.",
      });
    }
  };

  deleteCollateral = (value, tableMeta) => {
    let collaterals_id = tableMeta.rowData[5];
    this.setState({
      collaterals_id: collaterals_id,
      errorMessageDelete: "",
      successMessageDelte: "",
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteMarketingCollaterals = async () => {
    this.setState({ isProcessing: true });
    let collaterals_id = this.state.collaterals_id;
    const servicesResponse = await deleteCollaterals(
      auth.getAccount(),
      auth.getToken(),
      collaterals_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelte: "Document deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlert").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDelete: "Error document not found.",
      });
    } else {
      this.setState({
        errorMessageDelete: "Error failed to delete document.",
      });
    }
  };

  handleDeleteClient = (id) => {
    let client_id = id;
    this.setState({
      client_id: client_id,
      errorMessageDeleteClientDoc: "",
      successMessageDelteClientDoc: "",
    });
    window.$("#modalDeleteAlertClientDoc").modal("show");
  };

  deleteClientDoc = async () => {
    this.setState({ isProcessing: true });
    let client_id = this.state.client_id;
    const servicesResponse = await deleteClientDocument(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelteClientDoc: "Client Document deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlertClientDoc").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDeleteClientDoc: "Error document not found.",
      });
    } else {
      this.setState({
        errorMessageDeleteClientDoc: "Error failed to delete document.",
      });
    }
  };

  handleDeleteAgent = (id) => {
    let agent_id = id;
    this.setState({
      agent_id: agent_id,
      errorMessageDeleteAgentDoc: "",
      successMessageDelteAgentDoc: "",
    });
    window.$("#modalDeleteAlertAgentDoc").modal("show");
  };

  deleteAgentDoc = async () => {
    let agent_id = this.state.agent_id;
    this.setState({ isProcessing: true });
    const servicesResponse = await deleteAgentDocument(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelteAgentDoc: "Client Document deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlertAgentDoc").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDeleteAgentDoc: "Error document not found.",
      });
    } else {
      this.setState({
        errorMessageDeleteAgentDoc: "Error failed to delete document.",
      });
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  modalHideDelClientDoc = () => {
    window.$("#modalDeleteAlertClientDoc").modal("hide");
  };

  modalHideDelAgentDoc = () => {
    window.$("#modalDeleteAlertAgentDoc").modal("hide");
  };

  downloadCreditAppPdf = async (d_f_name) => {
    //this.setState({ processing_request: true });
    var url = APP_LIVE_URL + "files_data/collaterals/" + d_f_name;
    console.log("url: ", url);
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        //this.setState({ processing_request: false });
      });
  };

  setNewCollateral = async () => {
    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: "",
    });
    window.$("#modalNewCollateral").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  assignedTo = (value, tableMeta) => {
    let is_default = value;

    if (is_default === "none") {
      return <span className="badge badge-outline-secondary">None</span>;
    } else if (is_default === "all") {
      return (
        <span className="badge badge-outline-secondary">
          All Agents and Clients
        </span>
      );
    } else if (is_default === "agents") {
      return (
        <span
          className="badge badge-outline-secondary"
          onClick={() => this.openAttachedAgents(value, tableMeta)}
          style={{ cursor: "pointer" }}
        >
          Agents
        </span>
      );
    } else if (is_default === "clients") {
      return (
        <span
          className="badge badge-outline-secondary"
          onClick={() => this.openAttachedClients(value, tableMeta)}
          style={{ cursor: "pointer" }}
        >
          Clients
        </span>
      );
    }
  };

  openAttachedClients = async (value, tableMeta) => {
    let document_title = tableMeta.rowData[0];
    this.setState({
      client_details: [],
    });
    const servicesResponce = await getAttachedClients(
      auth.getAccount(),
      auth.getToken(),
      document_title
    );
    console.log("openAttachedClients.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "Clients not found."
    ) {
      this.setState({
        approveErrorMessage: "Clients not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the client files. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalAttachedClients").modal("show");
  };

  openAttachedAgents = async (value, tableMeta) => {
    let document_title = tableMeta.rowData[0];
    this.setState({
      agent_details: [],
    });
    const servicesResponce = await getAttachedAgents(
      auth.getAccount(),
      auth.getToken(),
      document_title
    );
    console.log("openAttachedAgents.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "Agents not found."
    ) {
      this.setState({
        approveErrorMessage: "Agetns not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agent_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the agent files. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalAttachedAgents").modal("show");
  };

  viewDownloadBtns = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadCreditAppPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download Document"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em class="icon ni ni-file-pdf"></em> View Document
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> View Document
          </span>
        </div>
      );
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".csv",
      ".xlsx",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_file: selectedFile,
      docErrorMessage: "",
      document_file_name: selectedFile.name,
    });
    //return true;
  };

  saveNewCollateral = async () => {
    this.setState({ isProcessing: true });

    const {
      document_file,
      document_file_name,
      document_title,
      group_id,
      group_title,
      selectedClients,
      selectedAgents,
      selectedTab,
    } = this.state;
    if (document_title === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Title field is required.",
        isProcessing: false,
      });
    } else if (document_file_name === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a document to upload.",
        isProcessing: false,
      });
    } else if (group_id === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a group to upload.",
        isProcessing: false,
      });
    } else if (selectedTab === "clients" && selectedClients.length === 0) {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please select at least one client.",
        isProcessing: false,
      });
    } else if (selectedTab === "agents" && selectedAgents.length === 0) {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please select at least one agent.",
        isProcessing: false,
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", document_file);
      formData.append("document_title", document_title);
      formData.append("document_for", selectedTab);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", document_file_name);
      formData.append("collateral_group_id", group_id);
      formData.append("collateral_group_title", group_title);

      if (this.state.selectedTab == "clients") {
        formData.append("selectedUser", JSON.stringify(selectedClients));
      } else {
        formData.append("selectedUser", JSON.stringify(selectedAgents));
      }

      axios
        .post(
          process.env.REACT_APP_API_URL +
            "collaterals/add_marketing_collaterals",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404 && res.data.error === "error") {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: res.data.message,
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              docSuccessMessage: "Document added successfully.",
              docErrorMessage: "",
              document_file_name: "",
              document_file: "",
              isProcessing: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage:
                "There is some error while adding the marketing document.",
              isProcessing: false,
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "",
      });
    }, 3000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  // handleSelectChangeClient = (selectedOptions) => {
  //   this.setState({ selectedClients: selectedOptions }, () => {
  //     let temp = false;
  //     if (this.state.clientsList.length === this.state.selectedClients.length) {
  //       temp = true;
  //     }
  //     this.setState({ selectAllClient: temp });
  //   });
  // };

  // handleSelectChangeAgent = (selectedOptions) => {
  //   this.setState({ selectedAgents: selectedOptions }, () => {
  //     let temp = false;
  //     if (this.state.agentList.length === this.state.selectedAgents.length) {
  //       temp = true;
  //     }
  //     this.setState({ selectAllAgent: temp });
  //   });
  // };

  // handleSelectAllChangeClient = () => {
  //   const { clientsList, selectAllClient } = this.state;
  //   if (!selectAllClient) {
  //     this.setState({
  //       selectedClients: clientsList,
  //       selectAllClient: true,
  //     });
  //   } else {
  //     // Unselect all agents
  //     this.setState({
  //       selectedClients: [],
  //       selectAllClient: false,
  //     });
  //   }
  // };

  // handleSelectAllChangeAgent = () => {
  //   const { agentList, selectAllAgent } = this.state;
  //   if (!selectAllAgent) {
  //     this.setState({
  //       selectedAgents: agentList,
  //       selectAllAgent: true,
  //     });
  //   } else {
  //     // Unselect all agents
  //     this.setState({
  //       selectedAgents: [],
  //       selectAllAgent: false,
  //     });
  //   }
  // };

  handleSelectChange = (selectedOptions) => {
    const { selectedTab } = this.state;
    if (selectedTab === "clients") {
      this.setState({ selectedClients: selectedOptions }, () => {
        let temp = false;
        if (
          this.state.clientsList.length === this.state.selectedClients.length
        ) {
          temp = true;
        }
        this.setState({ selectAllClient: temp });
      });
    } else if (selectedTab === "agents") {
      this.setState({ selectedAgents: selectedOptions }, () => {
        let temp = false;
        if (this.state.agentList.length === this.state.selectedAgents.length) {
          temp = true;
        }
        this.setState({ selectAllAgent: temp });
      });
    }
  };

  handleSelectAllChange = () => {
    const {
      clientsList,
      agentList,
      selectAllClient,
      selectAllAgent,
      selectedTab,
    } = this.state;
    if (selectedTab === "clients") {
      if (!selectAllClient) {
        this.setState({
          selectedClients: clientsList,
          selectAllClient: true,
        });
      } else {
        this.setState({
          selectedClients: [],
          selectAllClient: false,
        });
      }
    } else if (selectedTab === "agents") {
      if (!selectAllAgent) {
        this.setState({
          selectedAgents: agentList,
          selectAllAgent: true,
        });
      } else {
        this.setState({
          selectedAgents: [],
          selectAllAgent: false,
        });
      }
    }
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  render() {
    const {
      selectedClients,
      selectedAgents,
      selectAllClient,
      selectAllAgent,
      selectedTab,
    } = this.state;
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Documents" icon="icon ni ni-file-docs" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        dataTarget="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        dataContent="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setNewCollateral()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Document
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div class="card" style={{ borderRadius: "0px" }}>
                        <div class="card-inner">
                          <div className="row g-gs mb-3">
                            <div className="col-md-6">
                              <div class="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Select Document Group
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div class="form-control-group">
                                  <Select
                                    options={this.state.all_collaterals_groups}
                                    name="selected_accountno"
                                    placeholder="Select a channel partner to view their orders."
                                    value={this.state.selected_accountno}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearchBar}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title=""
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalNewCollateral"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Add New Document
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.docErrorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.docErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.docSuccessMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.docSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="document_title"
                                      name="document_title"
                                      type="text"
                                      label="Title"
                                      defaultValue={this.state.document_title}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 -_]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Title of the document."
                                      autoFocus
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <Select
                                      options={
                                        this.state.all_collaterals_groups
                                      }
                                      name="selected_accountno"
                                      placeholder="Select a document group"
                                      value={this.state.selected_accountno}
                                      autoComplete="off"
                                      emptyMessage="Clients not found"
                                      onChange={this.handleChangeSearch}
                                      isClearable
                                      isSearchable
                                      components={animatedComponents}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          lineHeight: "40px",
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          class={`nav-link ${selectedTab === 'client' ? 'active' : ''}`}
                                          onClick={() => this.setState({ selectedTab: 'client' })}
                                          data-toggle="tab"
                                          href="#tabItem5"
                                        >
                                          <em class="icon ni ni-user"></em>
                                          <span>Client</span>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class={`nav-link ${selectedTab === 'agent' ? 'active' : ''}`}
                                          onClick={() => this.setState({ selectedTab: 'agent' })}
                                          data-toggle="tab"
                                          href="#tabItem6"
                                        >
                                          <em class="icon ni ni-lock-alt"></em>
                                          <span>Agent</span>
                                        </a>
                                      </li>
                                    </ul>
                                    <div class="tab-content">
                                      <div
                                        class="tab-pane active"
                                        id="tabItem5"
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  this.state.selectAllClient
                                                }
                                                onChange={
                                                  this
                                                    .handleSelectAllChangeClient
                                                }
                                                name="selectAllClient"
                                              />
                                            }
                                            label="Select all Clients"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedClients"
                                            value={this.state.selectedClients}
                                            isMulti
                                            name="colors"
                                            options={this.state.clientsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={
                                              this.handleSelectChangeClient
                                            }
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div class="tab-pane" id="tabItem6">
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  this.state.selectAllAgent
                                                }
                                                onChange={
                                                  this
                                                    .handleSelectAllChangeAgent
                                                }
                                                name="selectAllAgent"
                                              />
                                            }
                                            label="Select all Agents"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectAgents"
                                            value={this.state.selectedAgents}
                                            isMulti
                                            name="colors"
                                            options={this.state.agentList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={
                                              this.handleSelectChangeClient
                                            }
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "clients"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "clients",
                                            })
                                          }
                                        >
                                          {/* <em class="icon ni ni-user"></em> */}
                                          <span>Client</span>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "agents"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "agents",
                                            })
                                          }
                                        >
                                          {/* <em class="icon ni ni-lock-alt"></em> */}
                                          <span>Agent</span>
                                        </a>
                                      </li>
                                    </ul>
                                    <div class="tab-content">
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "clients"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllClient}
                                                onChange={
                                                  this.handleSelectAllChange
                                                }
                                                name="selectAllClient"
                                              />
                                            }
                                            label="Select all Clients"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedClients"
                                            value={selectedClients}
                                            isMulti
                                            name="clients"
                                            options={this.state.clientsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectChange}
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "agents"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllAgent}
                                                onChange={
                                                  this.handleSelectAllChange
                                                }
                                                name="selectAllAgent"
                                              />
                                            }
                                            label="Select all Agents"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedAgents"
                                            value={selectedAgents}
                                            isMulti
                                            name="agents"
                                            options={this.state.agentList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectChange} // Change this to handleSelectChange
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    {/* <MutextField
                                      id="document_for"
                                      name="document_for"
                                      select
                                      label="Select Document For"
                                      value={this.state.document_for}
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText=""
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <option key="sets100" value="none">
                                        None
                                      </option>
                                      <option key="sets101" value="all">
                                        All
                                      </option>
                                      <option key="sets102" value="agents">
                                        Agents
                                      </option>
                                      <option key="sets103" value="clients">
                                        Clients
                                      </option>
                                    </MutextField> */}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      Upload File
                                      <sup> *</sup>
                                    </label>
                                  </div>
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="document_file"
                                      className="file-block"
                                      name="document_file"
                                      defaultValue={this.state.document_file}
                                      accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .csv, .xlsx, .xls"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.saveNewCollateral()}
                                  className="btn btn-primary"
                                  disabled={
                                    this.state.disabled ||
                                    this.state.isProcessing
                                  } // disable button when processing
                                >
                                  {this.state.isProcessing ? (
                                    <span>Processing...</span>
                                  ) : (
                                    <span>Submit</span>
                                  )}
                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        Supported file formats include: PNG,
                                        JPG, JPEG, DOC, DOCX, PDF, CSV, XLSX,
                                        XLS.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                  {/* START DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlert"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDel();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Delete Collateral?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDelete !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageDelete}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelte !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessageDelte}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="caption-text">
                                Are you sure you want to delete this document
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDel();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteMarketingCollaterals();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>DELETING...</span>
                                ) : (
                                  <span>DELETE</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END DELETE MODAL  */}
                  {/**** Open Attach Client Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalAttachedClients"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Clients</h5>
                        </div>
                        <div class="modal-body">
                          <div class="g-4">
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Account
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Name</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Compnay
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Email</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Delete Document
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.client_details &&
                                  this.state.client_details.length > 0 ? (
                                    <>
                                      {this.state.client_details.map(
                                        (details, index) => (
                                          <tr key={`idx${index}`}>
                                            <td class="tb-col-os">
                                              {details[1]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[2]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[3]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[4]}{" "}
                                            </td>
                                            <td className="tb-col-os">
                                              <span
                                                className="badge badge-outline-danger"
                                                onClick={() =>
                                                  this.handleDeleteClient(
                                                    details[0]
                                                  )
                                                } // Call handleDownload function with file URL
                                                style={{ cursor: "pointer" }}
                                              >
                                                <em
                                                  className="icon ni ni-trash"
                                                  style={{ fontSize: "18px" }}
                                                ></em>
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td class="tb-col-os" colSpan={4}>
                                        <strong className="text-danger">
                                          Record not found
                                        </strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Attach Client Modal ***/}

                  {/**** Open Attach Agent Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalAttachedAgents"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Agents</h5>
                        </div>
                        <div class="modal-body">
                          <div class="g-4">
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Account
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Name</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Compnay
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Email</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Delete Document
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.agent_details &&
                                  this.state.agent_details.length > 0 ? (
                                    <>
                                      {this.state.agent_details.map(
                                        (details, index) => (
                                          <tr key={`idx${index}`}>
                                            <td class="tb-col-os">
                                              {details[1]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[2]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[3]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[4]}{" "}
                                            </td>
                                            <td className="tb-col-os">
                                              <span
                                                className="badge badge-outline-danger"
                                                onClick={() =>
                                                  this.handleDeleteAgent(
                                                    details[0]
                                                  )
                                                } // Call handleDownload function with file URL
                                                style={{ cursor: "pointer" }}
                                              >
                                                <em
                                                  className="icon ni ni-trash"
                                                  style={{ fontSize: "18px" }}
                                                ></em>
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td class="tb-col-os" colSpan={4}>
                                        <strong className="text-danger">
                                          Record not found
                                        </strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Attach Agent Modal ***/}
                  {/* START Client DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlertClientDoc"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDelClientDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Delete Client Document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDeleteClientDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageDeleteClientDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelteClientDoc !==
                              "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {
                                          this.state
                                            .successMessageDelteClientDoc
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="caption-text">
                                Are you sure you want to delete the client
                                document
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDelClientDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteClientDoc();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>DELETING...</span>
                                ) : (
                                  <span>DELETE</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Client DELETE MODAL  */}

                  {/* START Agent DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlertAgentDoc"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDelAgentDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Delete Agent Document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDeleteAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageDeleteAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelteAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageDelteAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="caption-text">
                                Are you sure you want to delete the agent
                                document
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDelAgentDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteAgentDoc();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>DELETING...</span>
                                ) : (
                                  <span>DELETE</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Client DELETE MODAL  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Collaterals);
