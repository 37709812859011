import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { THEME_COLOR } from "./../../config/config";

const useStyles = makeStyles({
  highlightedRow: {
    backgroundColor: "#d3d3d3",
  },
});

const theme = createMuiTheme({
  shadows: Array(5).fill("none"),
  root: {
    "& MuiInputLabel-outlined": {
      zIndex: 0,
    },
    "& MuiTableHead": {
      zIndex: 0,
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        zIndex: 0,
      },

      fixedHeader: {
        zIndex: 0,
      },
    },

    MuiTableFooter: {
      root: {
        background: "#f0f2f4",
      },
    },
  },
  MuiFormLabel: {
    zIndex: 0,
  },
  MuiTableHead: { zIndex: 0 },
  palette: {
    primary: {
      main: THEME_COLOR, // your color
    },
  },
});

export default function DataTable(props) {
  const classes = useStyles();
  console.log("DataTable Props:", props);
  const [sortedData, setSortedData] = useState(props.tableData || []);

  useEffect(() => {
    if (props.tableData && props.accountnoFromParams && props.log_type) {
      let matchingRowIndex = -1;

      if (props.log_type === "agreements") {
        // Check by both account number and agreement account number for agreements
        matchingRowIndex = props.tableData.findIndex(
          (row) =>
            Object.values(row).includes(props.accountnoFromParams) &&
            Object.values(row).includes(props.agreement_accountno)
        );
      } else {
        // Check by account number only for clients, credit_application, and agents
        matchingRowIndex = props.tableData.findIndex((row) =>
          Object.values(row).includes(props.accountnoFromParams)
        );
      }

      if (matchingRowIndex !== -1) {
        const updatedTableData = [...props.tableData];
        const [matchingRow] = updatedTableData.splice(matchingRowIndex, 1);
        setSortedData([matchingRow, ...updatedTableData]);
      }
    }
  }, [props.tableData, props.accountnoFromParams, props.log_type]);

  let responsive = "vertical";
  let tableBodyHeight = "100%";
  let tableBodyMinHeight = "300px";

  const options = {
    filter: true,
    responsive,
    tableBodyHeight,
    selectableRows: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [25, 50, 100],
    jumpToPage: true,
    rowHover: true,
    setRowProps: (row) => {
      if (props.accountnoFromParams && props.tableData) {
        let isAccountInRow = false;

        if (props.log_type === "agreements") {
          // Check if both account numbers are present in the row object
          isAccountInRow =
            Object.values(row).includes(props.accountnoFromParams) &&
            Object.values(row).includes(props.agreement_accountno);
        } else {
          // Check if the account number is present in the row object
          isAccountInRow = Object.values(row).includes(
            props.accountnoFromParams
          );
        }

        return {
          className: isAccountInRow ? classes.highlightedRow : "",
        };
      }
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <MUIDataTable
          title={props.title}
          data={sortedData}
          columns={props.columns}
          options={options}
          id="dTable"
        />
      </div>
    </ThemeProvider>
  );
}
