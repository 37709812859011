import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  addRfqQuestion,
  listQuestionGroup
} from "./../../../config/reseller_api_calls";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import DataTable from "../../../components/Tables/DataTable";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class EditQuestionsLibrary extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      question_title: "",
      question_type: "textfield",
      show_container: false,
      misc_rows: [{}],
      disabled: false,
      button_processing: false,
      question_groups: [],
      selected_group : "",
      isMandatory: 0
    };
  }

  async componentDidMount() {
    const listGroupsResponse = await listQuestionGroup(
      auth.getAccount(),
      auth.getToken(),
    );
    console.log("listQuestionGroup: ", listGroupsResponse.data);
    if (
      listGroupsResponse.data.status === 403 ||
      listGroupsResponse.data.errors === "authentication missing" ||
      listGroupsResponse.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (listGroupsResponse.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting Question Groups.",
        successMessage: "",
        button_processing: false,
      });
    } else if (
      listGroupsResponse.data.status === 200 &&
      listGroupsResponse.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        question_groups: listGroupsResponse.data.data,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting Question Groups.",
        successMessage: "",
        button_processing: false,
      });
    }
  }
  //Test SMTP Settings

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  addRfqQuestion = async () => {
    let { question_title, question_type, misc_rows, selected_group, isMandatory } = this.state;
    this.setState({
      errorMessage: "",
      successMessage: "",
      button_processing: true,
    });
    var is_validated = true;
    if (question_title === "") {
      this.setState({
        errorMessage: "Question text is required.",
        button_processing: false,
      });
      is_validated = false;
    } else if (selected_group === "") {
      this.setState({
        errorMessage: "Please assign this question to a group.",
        button_processing: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      console.log("misc_rows: ", misc_rows);
      const servicesResponce = await addRfqQuestion(
        auth.getAccount(),
        auth.getToken(),
        question_title,
        question_type,
        misc_rows,
        selected_group,
        isMandatory
      );
      console.log("addRfqQuestion: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        //auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while adding the RFQ question.",
          successMessage: "",
          button_processing: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Question added successfully.",
          button_processing: false,
          is_working: "yes",
        });
        setTimeout(() => {
          this.props.props.history.push("/QuestionsLibrary");
        }, 3000);
      } else {
        this.setState({
          errorMessage: "There is some error while adding the RFQ question.",
          successMessage: "",
          button_processing: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);
  };

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("name: " + name + " " + value);
    //if (value === "radio" || value === "checkbox" || value === "dropdown") {
    this.showAnswerContainer(value);
    //}
  };

  setQuestionGroup = async(event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      selected_group: value,
    });
  }

  showAnswerContainer = (question_type) => {
    if (
      question_type === "radio" ||
      question_type === "checkbox" ||
      question_type === "dropdown"
    ) {
      this.setState({
        show_container: true,
      });
    } else {
      this.setState({
        show_container: false,
      });
    }
  };

  handleChangeMisc = (idx) => (e) => {
    const { name, value } = e.target;
    const misc_rows = [...this.state.misc_rows];
    misc_rows[idx][name] = value;

    this.setState({
      misc_rows,
      isBlocking: false,
    });
  };

  handleAddRow = () => {
    const item = {
      misc_title: "",
    };
    this.setState({
      misc_rows: [...this.state.misc_rows, item],
    });
  };

  handleRemoveRow = () => {
    this.setState({
      misc_rows: this.state.misc_rows.slice(0, -1),
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    const misc_rows = [...this.state.misc_rows];
    misc_rows.splice(idx, 1);
    this.setState({ misc_rows });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/QuestionsLibrary")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Questions Library
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add RFQ Question
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                {/* Table 5th Col Start */}
                <div className="col-xxl-12">
                  <div className="nk-block nk-block-lg">
                    <div className="card card-bordered">
                      <div className="card-inner">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="row g-4">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="Request for quotation question."
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Question Text{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="question_title"
                                  name="question_title"
                                  type="text"
                                  label="Question Text"
                                  defaultValue={this.state.question_title}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="Request for quotation answer type. i.e text field, textarea, checkbox, radio or dropdown."
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Answer Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <MutextField
                                  id="question_type"
                                  name="question_type"
                                  select
                                  label="Select Answer Type"
                                  value={this.state.question_type}
                                  onChange={this.setMarkUp}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select an answer type."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="sets10" value="textfield">
                                    Text Field
                                  </option>
                                  <option key="sets11" value="textarea">
                                    Text Area
                                  </option>
                                  <option key="sets12" value="radio">
                                    Radio Buttons
                                  </option>
                                  <option key="sets13" value="checkbox">
                                    Checkbox
                                  </option>

                                  <option key="sets14" value="dropdown">
                                    Drop Down
                                  </option>
                                </MutextField>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="Non-mandatory question will be optional"
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Is Mandatory{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <MutextField
                                  id="isMandatory"
                                  name="isMandatory"
                                  select
                                  value={this.state.isMandatory}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Is this question mandatory to answer?"
                                  variant="outlined"
                                  fullWidth
                                  required
                                >
                                  <option key="0" value="0">
                                    No
                                  </option>
                                  <option key="1" value="1">
                                    Yes
                                  </option>
                                </MutextField>
                              </div>
                            </div>
                          </div>
                          {this.state.question_groups.length > 0 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="Set of questions that this question will be a part of"
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Group{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <MutextField
                                  id="selected_group"
                                  name="selected_group"
                                  select
                                  value={this.state.selected_group}
                                  onChange={this.setQuestionGroup}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value="">Select a group</option>
                                  {this.state.question_groups
                                    .filter(group => group[2] === "1" && group[3] === "0")
                                    .map(group => (
                                      <option key={group[4]} value={group[4]}>
                                        {group[1]}
                                      </option>
                                  ))}
                                </MutextField>
                              </div>
                            </div>
                          </div>) : null }
                          {this.state.show_container === true ? (
                            <>
                              <div className="col-lg-12">
                                <h6
                                  style={{
                                    borderTop: "1px solid #e5e9f2",
                                    paddingTop: "20px",
                                  }}
                                >
                                  Answer Options
                                </h6>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <table
                                    className="table table-bordered"
                                    id="tab_logic"
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th style={{ lineHeight: "28px" }}>
                                          {" "}
                                          Answers{" "}
                                        </th>

                                        <th className="text-center">
                                          {this.state.misc_rows.length < 5 ? (
                                            <span
                                              className="badge badge-outline-success"
                                              onClick={this.handleAddRow}
                                              style={{
                                                cursor: "pointer",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                paddingLeft: "7px",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <em className="icon ni ni-plus"></em>
                                            </span>
                                          ) : null}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.misc_rows.map((item, idx) => (
                                        <tr id={`addr${idx}`} key={`idx${idx}`}>
                                          <td
                                            style={{
                                              paddingTop: "20px",
                                            }}
                                          >
                                            <MutextField
                                              id="misc_title"
                                              name="misc_title"
                                              label="Answer Option"
                                              defaultValue={
                                                this.state.misc_rows[idx]
                                                  .misc_title
                                              }
                                              variant="outlined"
                                              onChange={this.handleChangeMisc(
                                                idx
                                              )}
                                              fullWidth
                                            />
                                          </td>

                                          <td
                                            className="text-center"
                                            style={{
                                              paddingTop: "20px",
                                            }}
                                          >
                                            <span
                                              className="badge badge-outline-danger"
                                              onClick={this.handleRemoveSpecificRow(
                                                idx
                                              )}
                                              style={{
                                                cursor: "pointer",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                paddingLeft: "7px",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <em className="icon ni ni-trash"></em>
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="col-md-12 text-right">
                            {this.state.button_processing === true ? (
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span> Loading... </span>
                              </button>
                            ) : (
                              <>
                                <a
                                  onClick={() =>
                                    this.addMenuHistory("/QuestionsLibrary")
                                  }
                                  className="btn btn-lg btn-light"
                                  disabled={this.state.disabled}
                                  style={{ marginRight: "15px" }}
                                >
                                  <em className="icon ni ni-step-back"></em>
                                  Back
                                </a>
                                <button
                                  type="button"
                                  onClick={() => this.addRfqQuestion()}
                                  className="btn btn-lg btn-primary"
                                  disabled={this.state.disabled}
                                >
                                  Save Question
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditQuestionsLibrary);
