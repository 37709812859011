import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  addAgentSubRole,
  ListAgentParentRoles,
} from "./../../../config/agent_role_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "@tinymce/tinymce-react";

const auth = new HelperClass();

class AddAgentSubRole extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      role_title: "",
      role_link: "",
      role_icon: "",
      role_sequence: "",
      role_status: 0,
      role_default: 0,
      role_parent: "",
      parentMenuList: [],
      tableLoader: false,
      role_title_error: "",
      role_link_error: "",
      role_icon_error: "",
      role_sequence_error: "",
      subagentRoleCheckBox: false,
    };
  }
  async componentDidMount() {
    this.getParentRoleList();
  }
  getParentRoleList = async () => {
    const listAgentParentRoleResponce = await ListAgentParentRoles(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Get parent Role Res: ", listAgentParentRoleResponce.data.data);
    if (
      listAgentParentRoleResponce.data.status === 403 ||
      listAgentParentRoleResponce.data.errors === "authentication missing" ||
      listAgentParentRoleResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentParentRoleResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentParentRoleResponce.data.status === 200 &&
      listAgentParentRoleResponce.data.message === "success"
    ) {
      this.setState({
        parentMenuList: listAgentParentRoleResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    let error = "";
    let disabled = false;
    if (name === "role_title") {
      let isValid = /^[A-Za-z0-9 .]+$/.test(value);
      if (!isValid) {
        error =
          "Invalid characters. Only letters (both uppercase and lowercase), numbers, and spaces are allowed.";
        disabled = true;
      }
    }

    if (name === "role_link") {
      let isValid = /^[A-Za-z0-9 -_/]+$/.test(value);
      if (!isValid) {
        error =
          "Invalid characters. Only letters (both uppercase and lowercase), numbers, #, and / are allowed.";
        disabled = true;
      } else if (value.includes(" ")) {
        error = "Spaces are not allowed.";
        disabled = true;
      }
    }
    if (name === "role_icon") {
      let isValid = /^[A-Za-z0-9- ]+$/.test(value);
      if (!isValid) {
        error =
          "Invalid characters. Only letters (both uppercase and lowercase), numbers, hyphen (-), and space are allowed.";
        disabled = true;
      }
    }
    if (name === "role_sequence") {
      let isValid = /^[0-9]+$/.test(value);
      if (!isValid) {
        error = "Invalid characters. Only numbers are allowed.";
        disabled = true;
      }
    }

    this.setState({
      [name]: value,
      [`${name}_error`]: error,
      errorMessage: error,
      disabled: disabled,
    });
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { role_title, role_link, role_icon, role_sequence, role_parent } =
      this.state;

    var validated = true;

    if (role_title === "") {
      validated = false;
    } else if (role_link === "") {
      validated = false;
    } else if (role_icon === "") {
      validated = false;
    } else if (role_sequence === "") {
      validated = false;
    } else if (role_parent === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addAgentSubRole(
        auth.getAccount(),
        auth.getToken(),
        role_title,
        role_link,
        role_icon,
        role_sequence,
        role_parent,
        this.state.role_status,
        this.state.role_default,
        this.state.subagentRoleCheckBox.toString()
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage:
            "There is some error while Adding the Channel Partner Sub Role.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Channel Partner Sub Role Added successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/agent_roles");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/agent_roles")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Channel Partner Roles
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add Sub Role
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add Channel Partner Sub Role
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="role_title"
                                name="role_title"
                                type="text"
                                label="Role Title"
                                value={this.state.role_title}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                                error={Boolean(this.state.role_title_error)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="role_link"
                                name="role_link"
                                type="text"
                                label="Role  Link"
                                value={this.state.role_link}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                                error={Boolean(this.state.role_link_error)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="role_icon"
                                name="role_icon"
                                type="text"
                                label="Role  Icon"
                                value={this.state.role_icon}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                                error={Boolean(this.state.role_icon_error)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="role_sequence"
                                name="role_sequence"
                                type="text"
                                label="Role  Sequence"
                                value={this.state.role_sequence}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 3 }}
                                variant="outlined"
                                fullWidth
                                error={Boolean(this.state.role_sequence_error)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="role_parent"
                                name="role_parent"
                                select
                                label="Select Parent Role"
                                value={this.state.role_parent}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Parent Role"
                                variant="outlined"
                                fullWidth
                              >
                                <option value="" key="0">
                                  Select Parent Role
                                </option>
                                {this.state.parentMenuList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.alt}
                                  </option>
                                ))}
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="role_status"
                                name="role_status"
                                select
                                label="Select Status"
                                value={this.state.role_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Role Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Active
                                </option>
                                <option key="0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="role_default"
                                name="role_default"
                                select
                                label="Default Role"
                                value={this.state.role_default}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Default Role"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Active
                                </option>
                                <option key="0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <div
                                class="custom-control custom-control-lg custom-checkbox"
                                style={{ padding: 0, marginLeft: "3rem" }}
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck3"
                                  onChange={() => {
                                    const isChecked =
                                      !this.state.subagentRoleCheckBox;
                                    this.setState({
                                      subagentRoleCheckBox: isChecked,
                                    });
                                    console.log(
                                      "Sub Channel Partner Checkbox Checked:",
                                      isChecked
                                    );
                                  }}
                                />

                                <label
                                  class="custom-control-label"
                                  for="customCheck3"
                                >
                                  For Sub Channel Partner
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAgentSubRole);
