import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  COMPANY_NAME,
  KYC_URL,
  APP_URL,
  AGENT_PORTAL_URL,
} from "../../../config/config";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail } from "../../../config/utility";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";
import { withRouter } from "react-router-dom";

import {
  listClients,
  listServicesGroups,
  listAssignedServicesWithProjection,
  listAssignedServicesAgreements,
  assignServicesToCompany,
  revokeClientSignedAgreements,
  sendUnsignedClientEmail,
  submitClientServicesData,
  getClientSettings,
  getAgreementPdf,
  getClientKycDetail,
  initiateClientKyc,
  emailKycDetails,
  resendActivation,
  getCreditApplications,
  rejectCreditApplication,
  approveCreditApplication,
  listAgents,
  sendClientInvite,
  submitKycApproval,
  clientLoginSso,
  listAgentSharelinks,
  getClientInfo,
  editClient,
  approveClientSignedAgreement,
} from "./../../../config/api_calls";
import {
  ListClientAssignRoles,
  editClientAssignRole,
} from "../../../config/client_role_api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { Editor } from "@tinymce/tinymce-react";
import TextField from "../../../components/InputFields/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
} from "./../../../config/config";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import countryList from "react-select-country-list";

const animatedComponents = makeAnimated();
const auth = new HelperClass();
class Clients extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      formLoader: true,
      signupLoader: true,
      sharelinkLoader: false,
      showServiceLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      teamsArray: [],
      tfnTermArray: [],
      azTermArray: [],
      cnamArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      cnam_service: "",
      liveagent_service: "",

      loginErrorMessage: "",
      login_url: "",

      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      showSendEmail: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      emptyService: 0,
      all_client_data: [],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      showKycApproved: false,
      showApprovedAction: "",
      showApprovedAction: "",
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      client_accountno: "",
      kyc_email_address: "",
      kyc_approved: "",
      credit_applications: [],
      processing_request: false,
      creditErrorMessage: "",
      creditSuccessMessage: "",
      application_no: "",
      rejection_container: false,
      rejection_reason: "",
      select_agent: "",
      client_email: "",
      client_name: "",
      clientErrorMessage: "",
      clientSuccessMessage: "",
      selected_agent: null,
      selected_agent_accountno: "",
      all_active_agents: [],
      all_sharelinks: [],
      sharelinkErrorMessage: "",
      client_comp_name: "",
      client_update_name: "",
      client_title: "",
      client_update_email: "",
      client_phone: "",
      client_address: "",
      client_country: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_password: "",
      client_confirm_password: "",
      errorClientTitle: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientPass: "",
      errorClientAddress: "",
      errorClientCountry: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientMessage: "",
      errorClientMessageEdit: "",
      successClientMessage: "",
      successClientMessageEdit: "",
      isApproveActive: false,
      approve_client_id: "",
      approve_agreement_accountno: "",
      notifyCheckBox: false,
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      columnServices: [
        { name: "ID" },
        { name: "NAME" },
        { name: "COMPANY" },
        { name: "PHONE" },
        { name: "EMAIL" },
        { name: "AGENT" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "AGREEMENTS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultTextAgreement(value)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.editClientModal(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Edit Client"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Client</span>
                                </a>
                              </li>
                              {/*<li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showServices(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Add/Edit rates"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Assign Rates</span>
                                </a>
                                </li>*/}
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistoryLeft(
                                      "/viewClient/" + tableMeta.rowData[0]
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-setting"></em>

                                  <span>Assigned Services</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientAgreements(value, tableMeta)
                                  }
                                  title="Client Agreements"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Agreements</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistoryLeft(
                                      "/Quotations/" + tableMeta.rowData[0]
                                    )
                                  }
                                  title="Client quotations"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Client RFQ's</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientServices(value, tableMeta)
                                  }
                                  title="Client Notes"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Client Notes</span>
                                </a>
                              </li>
                              {tableMeta.rowData[7] === "inactive" ? (
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.resendActivation(value, tableMeta)
                                    }
                                    title="Resend Activation"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-signin"></em>

                                    <span>Resend Activation</span>
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Client KYC Form"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-user-list"></em>
                                  <span>KYC Form</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showCreditApplications(
                                      value,
                                      tableMeta
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Credit Applications"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-coin"></em>
                                  <span>Credit Applications</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistoryLeft(
                                      "clients/subclients/" +
                                        tableMeta.rowData[0]
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-users"></em>
                                  <span>Sub Clients</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentAssignRole(value, tableMeta)
                                  }
                                  title="Assign Role"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Role</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientLoginSso(value, tableMeta)
                                  }
                                  title="Login to the Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Dashboard</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    this.countryOptions = countryList().getData();
  }
  async componentDidMount() {
    const clientsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Client Data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      //console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        all_client_data: clientsResponce.data.all_data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  clientLoginSso = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[0];
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await clientLoginSso(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  agentAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[0];
    const agentEmail = tableMeta.rowData[4];
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
    });
    window.$("#modalAgentAssignRole").modal("show");
    this.getAgentRoleData(agentId, agentEmail);
  };

  getAgentRoleData = async (agentId, agentEmail) => {
    const listAgentAssignRoles = await ListClientAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListAgentRoles Res: ", listAgentAssignRoles.data.data);
    if (
      listAgentAssignRoles.data.status === 403 ||
      listAgentAssignRoles.data.errors === "authentication missing" ||
      listAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentAssignRoles.data.status === 200 &&
      listAgentAssignRoles.data.message === "success"
    ) {
      this.setState({
        assignRoleData: listAgentAssignRoles.data.data.roles,
        assignSubRoleData: listAgentAssignRoles.data.data.subroles,
        agentRoleLoader: false,
      });

      const initialActiveRoles = this.state.assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          id: role.id,
          isChecked: true,
        }));

      const initialActiveSubRoles = this.state.assignSubRoleData
        .filter((subrole) => subrole.isactive === 1)
        .map((subrole) => ({
          id: subrole.id,
          isChecked: true,
        }));
      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  changedRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { changedRolesRights } = this.state;

    const index = changedRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedChangedRolesRights = [...changedRolesRights];
      updatedChangedRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ changedRolesRights: updatedChangedRolesRights });
    } else {
      this.setState((prevState) => ({
        changedRolesRights: [
          ...prevState.changedRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  subchangedRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedRolesRights } = this.state;

    const index = subchangedRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedChangedRolesRights = [...subchangedRolesRights];
      updatedChangedRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedRolesRights: updatedChangedRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedRolesRights: [
          ...prevState.subchangedRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  changedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { changedSubRolesRights } = this.state;

    const index = changedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...changedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ changedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        changedSubRolesRights: [
          ...prevState.changedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  subchangedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedSubRolesRights } = this.state;

    const index = subchangedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...subchangedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedSubRolesRights: [
          ...prevState.subchangedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  handleSubmitRoleCheckbox = async () => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });
    const RolesRights = this.state.changedRolesRights;
    const SubRolesRights = this.state.changedSubRolesRights;
    //console.log("RolesRights ", RolesRights);
    //console.log("SubRolesRights ", SubRolesRights);

    const saveResponce = await editClientAssignRole(
      auth.getAccount(),
      auth.getToken(),
      RolesRights,
      SubRolesRights
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      // window.location.replace("/error");
      this.setState({
        errorMessageAgentRole:
          "There is some error while updating the Client Role.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageAgentRole: "",
        successMessageAgentRole: "Client Role updated successfully.",
        disableRoleBtn: false,
      });
      setTimeout(() => {
        //this.props.props.history.push("/agent_roles");
        window.$("#modalAgentAssignRole").modal("hide");
      }, 3000);
    } else {
      this.setState({
        errorMessageAgentRole: saveResponce.data.message,
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
      //window.location.replace("/error");
    }
  };

  defaultTextAgreement = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split(" / ");

    if (is_default !== "") {
      return (
        <>
          <span className="badge badge-outline-primary">
            Total: {is_default[0]}
          </span>
          <br />
          <span className="badge badge-outline-success">
            Signed: {is_default[1]}
          </span>
          <br />
          <span className="badge badge-outline-warning">
            Unsigned: {is_default[2]}
          </span>
        </>
      );
    }
  };

  resendActivation = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[0];
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivation(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("resendActivation: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  clientServices = async (value, tableMeta) => {
    let company_title = tableMeta.rowData[1];
    let client_id = tableMeta.rowData[0];
    const servicesResponce = await getClientSettings(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    //console.log("getClientSettings: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        client_services_welcome_content: servicesResponce.data.data,
        client_services_email: "",
        errorMessage: "",
        successMessage: "",
        company_title: company_title,
        client_services_client_id: client_id,
      });
    }

    window.$("#modalFormClientServices").modal("show");
  };

  clientAgreements = async (value, tableMeta) => {
    let company_title = tableMeta.rowData[1];
    let company_id = tableMeta.rowData[0];
    this.setState({
      isRevokeReasonActive: false,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      client_accountno: company_id,
    });
    // this.setState({
    //   company_title: company_title
    // });
    // window.$("#modalFormClientAgreements").modal("show");
    // return;

    //console.log("tableMeta: ", tableMeta);
    let clientAgreementsInfo = [];
    const servicesResponce = await listAssignedServicesAgreements(
      auth.getAccount(),
      auth.getToken(),
      company_id
    );

    console.log("clientAgreements: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.clientAgreementsInfo &&
        servicesResponce.data.clientAgreementsInfo.length > 0
      ) {
        clientAgreementsInfo = servicesResponce.data.clientAgreementsInfo;
      }
      this.setState({
        company_title: company_title,
        signedData: clientAgreementsInfo.filter(
          (data) => data.is_signed === "Yes"
        ),
        unsignedData: servicesResponce.data.unSignedAgreements,
        errorMessage: "",
        successMessage: "",
      });
    } else {
      this.setState({
        company_title: company_title,
        signedData: [],
        unsignedData: [],
        errorMessage: "",
        successMessage: "",
      });
    }

    //let company_title = tableMeta.rowData[1];

    window.$("#modalFormClientAgreements").modal("show");
  };

  reloadClientAgreements = async (company_id) => {
    let clientAgreementsInfo = [];
    const servicesResponce = await listAssignedServicesAgreements(
      auth.getAccount(),
      auth.getToken(),
      company_id
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.clientAgreementsInfo &&
        servicesResponce.data.clientAgreementsInfo.length > 0
      ) {
        clientAgreementsInfo = servicesResponce.data.clientAgreementsInfo;
      }
      this.setState({
        signedData: clientAgreementsInfo.filter(
          (data) => data.is_signed === "Yes"
        ),
        unsignedData: servicesResponce.data.unSignedAgreements,
        errorMessage: "",
        successMessage: "",
      });
    } else {
    }
  };

  revokeAgreement = async (agreement_accountno) => {
    //console.log('revokeAgreement',agreement_id,agreement_accountno);
    this.setState({
      isRevokeReasonActive: true,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      revokeAgreementData: {
        agreement_accountno: agreement_accountno,
      },
    });
    //console.log("agreement_accountno: ", agreement_accountno);
  };

  showServices = async (value, tableMeta) => {
    const servicesResponce = await listServicesGroups(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      errorMessage: "",
      successMessage: "",
      emptyService: 0,
    });

    window.$("#modalFormDetail").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    }
  };

  showAssignedServices = async (value, tableMeta) => {
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      showServiceLoader: false,
    });
    window.$("#modalAssignedServices").modal("show");
    const servicesResponce = await listAssignedServicesWithProjection(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        showServiceLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        packagesAssignedData: servicesResponce.data.package_list,
        showServiceLoader: false,
      });
    } else {
      this.setState({
        showServiceLoader: false,
      });
    }
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  showDetails = async (value, tableMeta) => {
    let extra_details = tableMeta.rowData[0];

    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      client_accountno: extra_details,
      kycErrorMessage: "",
      loading_button: false,
      kycSuccessMessage: "",
      kyc_approved: "",
      kyc_email_address: tableMeta.rowData[4],
    });

    const kycResponce = await getClientKycDetail(
      auth.getAccount(),
      auth.getToken(),
      extra_details
    );
    //console.log("getClientKycDetail: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting client details. Please try again later.",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        kycErrorMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      let completed_percentage =
        (100 / kycResponce.data.steps_allowed) *
        kycResponce.data.steps_completed;
      this.setState({
        kyc_details: kycResponce.data.data,
        kyc_key: kycResponce.data.kyc_key,
        steps_allowed: kycResponce.data.steps_allowed,
        steps_completed: kycResponce.data.steps_completed,
        completed_percentage: completed_percentage.toFixed(0),
        client_register_date: this.format_date(kycResponce.data.register_date),
        kyc_approved: kycResponce.data.kyc_approved,
      });
    } else {
      this.setState({
        kycErrorMessage: "",
      });
    }

    window.$("#modalClientDetails").modal("show");
  };

  showCreditApplications = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[0];
    this.setState({ client_accountno: client_accountno });
    const kycResponce = await getCreditApplications(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("showCreditApplications: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        creditErrorMessage:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        credit_applications: kycResponce.data.data,
        processing_request: false,
      });
    } else {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
    }
    window.$("#modalCreditApplications").modal("show");
  };

  reloadCreditApplications = async () => {
    const { client_accountno } = this.state;
    this.setState({ client_accountno: client_accountno });
    const kycResponce = await getCreditApplications(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );

    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        creditErrorMessage:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        credit_applications: kycResponce.data.data,
        processing_request: false,
      });
    } else {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
    }
  };

  initiateKyc = async () => {
    const { client_accountno } = this.state;
    this.setState({
      kycErrorMessage: "",
      loading_button: true,
      kycSuccessMessage: "",
    });
    const kycResponce = await initiateClientKyc(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("initiateKyc: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting client details. Please try again later.",
        loading_button: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        loading_button: false,
        kycErrorMessage: "",
        kycSuccessMessage:
          "Kyc process initiated successfully. An email has also been sent to the customer with URL.",
      });
      setTimeout(function () {
        window.$("#modalClientDetails").modal("hide");
      }, 3000);
    } else {
      this.setState({
        kycErrorMessage:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        kycSuccessMessage: "",
      });
    }

    //window.$("#modalClientDetails").modal("show");
  };

  email_kyc_Details = async () => {
    const { client_accountno, kyc_email_address } = this.state;
    this.setState({
      kycErrorMessage: "",
      disabled: true,
      kycSuccessMessage: "",
    });
    if (kyc_email_address === "") {
      this.setState({
        kycErrorMessage: "Please enter the email address.",
        disabled: false,
        kycSuccessMessage: "",
      });
    } else if (ValidateEmail(kyc_email_address) === false) {
      this.setState({
        kycErrorMessage: "Please enter the valid email address.",
        disabled: false,
        kycSuccessMessage: "",
      });
    } else {
      const kycResponce = await emailKycDetails(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        kyc_email_address
      );
      //console.log("email_kyc_Details: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          kycErrorMessage:
            "There is an error while sending an email. Please try again later.",
          disabled: false,
          kycSuccessMessage: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          kycErrorMessage:
            "There is an error while sending an email. Please try again.",
          disabled: false,
          kycSuccessMessage: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          disabled: false,
          kycErrorMessage: "",
          kycSuccessMessage:
            "An email has been sent to the customer with URL to complete his profile.",
        });
      } else {
        this.setState({
          kycErrorMessage:
            "There is an error while sending an email. Please try again.",
          disabled: false,
          kycSuccessMessage: "",
        });
      }
    }
    setTimeout(() => {
      this.setState({ kycErrorMessage: "", kycSuccessMessage: "" });
    }, 5000);

    //window.$("#modalClientDetails").modal("show");
  };

  sendEmailUnsignedAgreement = async (agreement_accountno, client_id) => {
    this.setState({
      successMessage: "",
      errorMessage: "",
      isEmailSending: true,
      unsigned_email_agreement_accountno: agreement_accountno,
    });

    const servicesResponce = await sendUnsignedClientEmail(
      auth.getAccount(),
      auth.getToken(),
      client_id,
      agreement_accountno
    );

    this.setState({
      isEmailSending: false,
    });

    console.log("sendEmailUnsignedAgreement.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Email Sent Successfully",
      });
      setTimeout(function () {
        window.$("#modalFormAgentAgreements").modal("hide");
      }, 4000);
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleClientServicesSubmit = async (client_id, welcome_content, email) => {
    //console.log('handleClientAgreementRevokeSubmit',client_id, revoke_reason, agreement_accountno);
    const {
      client_services_client_id,
      client_services_welcome_content,
      client_services_email,
    } = this.state;

    let is_validated = true;
    if (client_services_welcome_content === "") {
      is_validated = false;
      this.setState({
        errorMessage: "Welcome content is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessage: "",
        errorMessage: "",
        disabled: true,
      });

      const servicesResponce = await submitClientServicesData(
        auth.getAccount(),
        auth.getToken(),
        client_services_client_id,
        client_services_welcome_content,
        client_services_email
      );
      //console.log("handleAgentAgreementRevokeSubmit.data: ", servicesResponce.data);
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessage:
            "There is an error while submitting a data. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is an error while submitting a data. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Client notes updated successfully.",
        });
        setTimeout(function () {
          window.$("#modalFormClientServices").modal("hide");
        }, 4000);
      } else {
        this.setState({
          errorMessage:
            "There is an error while submitting a data. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleClientAgreementRevokeSubmit = async (
    agreement_accountno,
    client_id,
    revoke_reason
  ) => {
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        errorMessage: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessage: "",
        errorMessage: "",
        disabled: true,
      });

      const servicesResponce = await revokeClientSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        client_id,
        agreement_accountno,
        revoke_reason
      );
      //console.log("handleAgentAgreementRevokeSubmit.data: ", servicesResponce.data);
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Agreement is revoked successfully",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        setTimeout(function () {
          window.$("#modalFormClientAgreements").modal("hide");
        }, 4000);
      } else {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  approveAgreement = async (agreement_accountno) => {
    const { client_accountno } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      disabled: true,
    });

    const servicesResponce = await approveClientSignedAgreement(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      agreement_accountno
    );
    console.log("approveAgreement.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.reloadClientAgreements(client_accountno);
      this.setState({
        successMessage: "Agreement approved successfully",
        isApproveActive: false,
      });
    } else {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
    }

    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handleChangeClientServices = async (event) => {
    const { target } = event;
    // console.log('handleChangeClientServicesWelcomeContent',target);
    // return;
    const value = target.value;
    const { name } = target;
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handle_accordion_head = (number) => {
    if (number === 0) {
      return "accordion-head";
    } else {
      return "accordion-head collapsed";
    }
  };

  handle_accordion_body = (number) => {
    if (number === 0) {
      return "accordion-body collapse show";
    } else {
      return "accordion-body collapse";
    }
  };

  renderServices = () => {
    let service_data = this.state.serviceData;
    //console.log(service_data);
    let didArray = [];
    let tfnArray = [];
    let termArray = [];
    let smsArray = [];
    let ivrArray = [];
    let liveagentArray = [];
    let teamsArray = [];
    let tfnTermArray = [];
    let azTermArray = [];
    let cnamArray = [];
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][5] == "did") {
        let insideArray1 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        didArray.push(insideArray1);
      }
      if (service_data[i][5] == "tfn") {
        let insideArray2 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        tfnArray.push(insideArray2);
      }
      if (service_data[i][5] == "term") {
        let insideArray3 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        termArray.push(insideArray3);
      }
      if (service_data[i][5] == "ivr") {
        let insideArray5 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        ivrArray.push(insideArray5);
      }
      if (service_data[i][5] == "live_agent") {
        let insideArray6 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        liveagentArray.push(insideArray6);
      }
      if (service_data[i][5] == "sms") {
        let insideArray4 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        smsArray.push(insideArray4);
      }
      if (service_data[i][5] == "teams") {
        let insideArray5 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        teamsArray.push(insideArray5);
      }
      if (service_data[i][5] == "tfn_term") {
        let insideArray6 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        tfnTermArray.push(insideArray6);
      }
      if (service_data[i][5] == "az_term") {
        let insideArray7 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        azTermArray.push(insideArray7);
      }
      if (service_data[i][5] == "az_term") {
        let insideArray8 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        cnamArray.push(insideArray8);
      }
    }
    this.setState({
      didArray: didArray,
      tfnArray: tfnArray,
      termArray: termArray,
      smsArray: smsArray,
      ivrArray: ivrArray,
      liveagentArray: liveagentArray,
      teamsArray: teamsArray,
      tfnTermArray: tfnTermArray,
      azTermArray: azTermArray,
      cnamArray: cnamArray,
    });
  };

  setPriceStates = async (
    did_service,
    tfn_service,
    term_service,
    sms_service,
    ivr_service,
    liveagent_service
  ) => {
    if (did_service != "") {
      let did_array = this.state.didArray;
      for (let i = 0; i < did_array.length; i++) {
        if (did_array[i][0] == did_service) {
          this.setState({
            nrc_did: did_array[i][3],
            mrc_did: did_array[i][4],
            rate_did: did_array[i][6],
          });
        }
      }
    }
    if (tfn_service != "") {
      let tfn_array = this.state.tfnArray;
      for (let j = 0; j < tfn_array.length; j++) {
        if (tfn_array[j][0] == tfn_service) {
          this.setState({
            nrc_tfn: tfn_array[j][3],
            mrc_tfn: tfn_array[j][4],
            rate_tfn: tfn_array[j][6],
          });
        }
      }
    }
    if (term_service != "") {
      let term_array = this.state.termArray;
      for (let k = 0; k < term_array.length; k++) {
        if (term_array[k][0] == term_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_term: term_array[k][6],
          });
        }
      }
    }

    if (ivr_service != "") {
      let ivr_array = this.state.ivrArray;
      for (let k = 0; k < ivr_array.length; k++) {
        if (ivr_array[k][0] == ivr_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_ivr: ivr_array[k][6],
          });
        }
      }
    }

    if (liveagent_service != "") {
      let la_array = this.state.liveagentArray;
      for (let k = 0; k < la_array.length; k++) {
        if (la_array[k][0] == liveagent_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_liveagent: la_array[k][6],
          });
        }
      }
    }

    if (sms_service != "") {
      let sms_array = this.state.smsArray;
      for (let k = 0; k < sms_array.length; k++) {
        if (sms_array[k][0] == sms_service) {
          //console.log("HERE TERM: ", sms_array[k][10]);
          this.setState({
            rate_smsin: sms_array[k][10],
            rate_smsout: sms_array[k][11],
          });
        }
      }
    }
  };

  formSave = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service,
    } = this.state;
    await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service
    );
    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToCompany(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      this.state.company_id,
      this.state.nrc_did,
      this.state.mrc_did,
      this.state.rate_did,
      this.state.nrc_tfn,
      this.state.mrc_tfn,
      this.state.rate_tfn,
      this.state.rate_term,
      sms_service,
      this.state.rate_smsin,
      this.state.rate_smsout,
      ivr_service,
      liveagent_service,
      this.state.rate_ivr,
      this.state.rate_liveagent
    );
    //console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning the services.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  downloadPdf = async (d_f_name, agreement_title) => {
    const reportsData = await getAgreementPdf(
      auth.getAccount(),
      auth.getToken(),
      d_f_name
    );
    //console.log("PDF.data: ", reportsData.data.data + " " + d_f_name);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      let file_name = agreement_title.replace(/[^a-zA-Z0-9]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  downloadKycPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      d_f_name = d_f_name + ".pdf";
      var url = APP_URL + "files_data/kyc_signup/" + d_f_name;
      console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            kycErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ kycErrorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  downloadCreditAppPdf = async (d_f_name) => {
    this.setState({ processing_request: true });
    var url = AGENT_PORTAL_URL + "files_data/credit_application/" + d_f_name;
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ processing_request: false });
      });
  };

  rejectCreditApplication = async () => {
    const { application_no, rejection_reason, client_accountno } = this.state;
    this.setState({ processing_request: true, disabled: true });
    if (rejection_reason === "") {
      this.setState({
        creditErrorMessage: "Please enter the rejection reason.",
        processing_request: false,
        disabled: false,
      });
    } else {
      const kycResponce = await rejectCreditApplication(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        application_no,
        rejection_reason
      );
      console.log("rejectCreditApplication: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          creditErrorMessage:
            "There is some error while rejecting the credit application.",
          processing_request: false,
          disabled: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "error"
      ) {
        this.setState({
          creditErrorMessage: "",
          processing_request: false,
          disabled: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          creditSuccessMessage: "Credit application rejected successfully.",
          processing_request: false,
          disabled: false,
        });
        setTimeout(() => {
          this.reloadCreditApplications();
        }, 4000);
      } else {
        this.setState({
          creditErrorMessage:
            "There is some error while rejecting the credit application.",
          processing_request: false,
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        creditErrorMessage: "",
        creditSuccessMessage: "",
      });
    }, 4000);
  };

  approveCreditApplication = async (application_no) => {
    const { client_accountno } = this.state;
    this.setState({ processing_request: true, disabled: true });

    const kycResponce = await approveCreditApplication(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      application_no
    );
    console.log("rejectCreditApplication: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        creditErrorMessage:
          "There is some error while approving the credit application.",
        processing_request: false,
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "error"
    ) {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        creditSuccessMessage: "Credit application approved successfully.",
        processing_request: false,
        disabled: false,
      });
      setTimeout(() => {
        this.reloadCreditApplications();
      }, 4000);
    } else {
      this.setState({
        creditErrorMessage:
          "There is some error while approving the credit application.",
        processing_request: false,
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({
        creditErrorMessage: "",
        creditSuccessMessage: "",
      });
    }, 4000);
  };

  setNewClientSettings = async () => {
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      client_name: "",
      client_email: "",
    });
    window.$("#modalAddNewClient").modal("show");
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        AgentsList: servicesResponce.data.data,
        formLoader: false,
      });
    } else {
      this.setState({
        formLoader: false,
      });
    }
  };

  saveNewClient = async () => {
    const { select_agent, client_name, client_email } = this.state;
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      disabled: true,
    });

    if (select_agent === "") {
      this.setState({
        clientErrorMessage: "Please select the agent.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (client_name === "") {
      this.setState({
        clientErrorMessage: "Please enter the name.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (client_email === "") {
      this.setState({
        clientErrorMessage: "Please enter the email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (ValidateEmail(client_email) === false) {
      this.setState({
        clientErrorMessage: "Please enter the valid email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendClientInvite(
        auth.getAccount(),
        auth.getToken(),
        select_agent,
        client_email,
        client_name
      );

      console.log("agentsResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          clientErrorMessage: "",
          clientSuccessMessage: "Invite successfully sent to the client.",
          disabled: false,
          client_email: "",
          client_name: "",
        });
      } else {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        clientErrorMessage: "",
        clientSuccessMessage: "",
      });
    }, 4000);
  };

  print_details = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  handleProjection = (projection) => {
    let projection_data = "";

    if (projection !== "") {
      projection_data = projection.split("###");
      return (
        <>
          <div className="coin-item" style={{ padding: "0px" }}>
            <div className="coin-info">
              <span className="coin-name">Monthly Usage</span>
              <span className="coin-text">{projection_data[0]}</span>
            </div>
          </div>
          <div className="coin-item" style={{ padding: "0px" }}>
            <div className="coin-info">
              <span className="coin-name">Total Numbers</span>
              <span className="coin-text">{projection_data[1]}</span>
            </div>
          </div>
          <div className="coin-item" style={{ padding: "0px" }}>
            <div className="coin-info">
              <span className="coin-name">Usage Desc</span>
              <span className="coin-text">{projection_data[2]}</span>
            </div>
          </div>
          <div className="coin-item" style={{ padding: "0px" }}>
            <div className="coin-info">
              <span className="coin-name">Sessions</span>
              <span className="coin-text">{projection_data[3]}</span>
            </div>
          </div>
        </>
      );
    } else {
      return "--";
    }
  };

  showApprovedCont = (kyc_action, accountno) => {
    this.setState({
      showKycApproved: true,
      showApprovedAction: kyc_action,
    });
  };

  submit_client_kyc_approval = async () => {
    const { company_id, kyc_reason, showApprovedAction } = this.state;
    this.setState({
      kycErrorMessage: "",
      disabled: true,
      kycSuccessMessage: "",
    });
    if (kyc_reason === "") {
      this.setState({
        kycErrorMessage: "Please enter the rejection / approval reason.",
        disabled: false,
        kycSuccessMessage: "",
      });
    } else {
      const kycResponce = await submitKycApproval(
        auth.getAccount(),
        auth.getToken(),
        company_id,
        kyc_reason,
        showApprovedAction
      );
      console.log("email_kyc_Details: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          kycErrorMessage:
            "There is an error while processing your request. Please try again later.",
          disabled: false,
          kycSuccessMessage: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          kycErrorMessage:
            "There is an error while processing your request. Please try again.",
          disabled: false,
          kycSuccessMessage: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          disabled: false,
          kycErrorMessage: "",
          kycSuccessMessage: "Your request has been processed successfully.",
          kyc_reason: "",
          kyc_approved: kycResponce.data.kyc_approved,
        });
        setTimeout(() => {
          this.setState({
            showKycApproved: false,
          });
        }, 4000);
      } else {
        this.setState({
          kycErrorMessage:
            "There is an error while processing your request. Please try again.",
          disabled: false,
          kycSuccessMessage: "",
        });
      }
    }
    setTimeout(() => {
      this.setState({ kycErrorMessage: "", kycSuccessMessage: "" });
    }, 5000);
  };

  addMenuHistoryLeft = (link) => {
    this.props.history.push(link);
  };

  getSignUpLink = async () => {
    this.setState({
      signupLoader: true,
      sharelinkErrorMessage: "",
      all_sharelinks: [],
      selected_agent: null,
    });
    window.$("#modalgetSignupLink").modal("show");
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getSignUpLink: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][1] +
                " (" +
                servicesResponce.data.data[i][2] +
                ")",
              value: servicesResponce.data.data[i][7],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_agents: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          selected_agent_accountno: changed_value.value,
          selected_agent: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.selected_agent_accountno);
          this.getAgentSharelinks();
        }
      );
    } else {
      this.setState({ selected_agent: null, all_sharelinks: [] });
    }
  };

  getAgentSharelinks = async () => {
    this.setState({
      sharelinkLoader: true,
      sharelinkErrorMessage: "",
      all_sharelinks: [],
    });

    const { selected_agent_accountno } = this.state;

    const servicesResponce = await listAgentSharelinks(
      auth.getAccount(),
      auth.getToken(),
      selected_agent_accountno
    );

    console.log("getAgentSharelinks: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        sharelinkLoader: false,
        sharelinkErrorMessage: "Share link not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        all_sharelinks: servicesResponce.data.data,
        sharelinkLoader: false,
        sharelinkErrorMessage: "",
      });
    } else {
      this.setState({
        sharelinkLoader: false,
        sharelinkErrorMessage: "Share link not found.",
      });
      // window.location.replace("/error");
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      client_country: country,
    });
  };

  editClientModal = async (value, tableMeta) => {
    const getClientInfoResponce = await getClientInfo(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      window.$("#modalUpdateClient").modal("show");
      this.setState({
        update_client_account_no: tableMeta.rowData[0],
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
      });
    } else {
      // window.location.replace("/error");
    }
    // console.log('edit client account_no : ',tableMeta.rowData[0]);
  };

  formUpdateClient = async () => {
    let {
      update_client_account_no,
      client_title,
      client_update_name,
      client_update_email,
      client_password,
      client_confirm_password,
      client_phone,
      client_comp_name,
      client_state,
      client_address,
      client_city,
      client_zip,
      client_country,
    } = this.state;

    let is_validated = true;
    if (this.state.client_comp_name === "") {
      this.setState({
        errorClientCompName: "error",
        errorClientPhone: "",
        errorClientName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.client_update_name === "") {
      this.setState({
        errorClientPhone: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientName: "error",
        errorClientMessageEdit: "Name is required.",
      });
      is_validated = false;
    } else if (this.state.client_phone === "") {
      this.setState({
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientPhone: "error",
        errorClientMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } /*else if (normalize(this.state.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    }*/ else if (ValidateEmail(this.state.client_update_email) === false) {
      this.setState({
        errorClientEmail: "error",
        errorClientMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.client_password !== "") {
      if (this.state.client_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.client_confirm_password === "") {
        this.setState({
          errorClientPass: "error",
          errorClientMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.client_confirm_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.client_password.trim() !=
        this.state.client_confirm_password.trim()
      ) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit:
            "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    } else if (this.state.client_address === "") {
      this.setState({
        errorClientMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.client_country === "") {
      this.setState({
        errorClientMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (this.state.client_city === "") {
      this.setState({
        errorClientMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.client_state === "") {
      this.setState({
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.client_zip === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    }
    if (is_validated) {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
      });
      let apiResponce = await editClient(
        auth.getAccount(),
        auth.getToken(),
        update_client_account_no,
        client_title,
        client_update_name,
        client_update_email,
        client_password,
        client_phone,
        client_comp_name,
        client_address,
        client_city,
        client_state,
        client_zip,
        client_country,
        this.state.notifyCheckBox.toString()
      );
      console.log("Edit Client RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successClientMessageEdit: "Client updated successfully.",
          errorClientMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.reload();
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const accountnoFromParams = searchParams.get("accountno");
    const log_type = searchParams.get("log_type");
    return (
      <div className="nk-content " id="Clients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}

              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Clients" icon="icon ni ni-users" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() => this.getSignUpLink()}
                              className="btn btn-info"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-signin"></em>
                              &nbsp;Signup Link
                            </Link>
                          </li>
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setNewClientSettings()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Client
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              {/** Login Modal Start **/}
              <div className="modal fade" tabindex="-1" id="modalDefaultLogin">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Login to the Client
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.loginErrorMessage === "" ? (
                        <div className="alert alert-pro alert-primary">
                          <div className="alert-text">
                            <h6>Connecting.....</h6>
                            <p>
                              Please wait while we are connecting to the client
                              Login. You will be redirected to the Client
                              dashboard after the authentication.
                            </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.loginErrorMessage !== "" ? (
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h6>Login Error</h6>
                            <p>{this.state.loginErrorMessage}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/** Login Modal Start **/}
              <div className="modal fade" tabindex="-1" id="modalgetSignupLink">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Client Signup Link
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        <div className="col-xxl-12">
                          <div className="nk-block">
                            <div className="card card-bordered">
                              <div className="card-inner">
                                {this.state.signupLoader === true ? (
                                  HelpLinksLoader()
                                ) : (
                                  <>
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Select Agent{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <Select
                                          options={this.state.all_active_agents}
                                          name="selected_accountno"
                                          placeholder="Select an agent to view the share links."
                                          value={this.state.selected_agent}
                                          autoComplete="off"
                                          emptyMessage="Clients not found"
                                          onChange={this.handleChangeSearch}
                                          isClearable
                                          isSearchable
                                          components={animatedComponents}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              lineHeight: "40px",
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {this.state.sharelinkLoader === true ? (
                            <div className="row g-gs">
                              <div className="col-xxl-12 mt-4">
                                {HelpLinksLoader()}
                              </div>
                            </div>
                          ) : (
                            <>
                              {this.state.sharelinkErrorMessage !== "" ? (
                                <div className="alert alert-pro alert-danger mt-4">
                                  <div className="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.sharelinkErrorMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.all_sharelinks &&
                              this.state.all_sharelinks.length > 0 ? (
                                <div className="row g-gs">
                                  <div className="col-xxl-12 mt-4">
                                    <h5>Share Links</h5>
                                    <div className="nk-block">
                                      <ul className="sp-pdl-list">
                                        {this.state.all_sharelinks.map(
                                          (sharelink) => (
                                            <li className="sp-pdl-item">
                                              <div className="sp-pdl-desc">
                                                <div className="sp-pdl-img">
                                                  <img
                                                    src="../../../assets/images/profile.svg"
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="sp-pdl-info">
                                                  <h6 className="sp-pdl-title">
                                                    <span className="sp-pdl-name">
                                                      {sharelink[0]}
                                                    </span>{" "}
                                                  </h6>
                                                  <div className="sp-pdl-meta"></div>
                                                </div>
                                              </div>
                                              <div className="sp-pdl-btn">
                                                <a
                                                  href={sharelink[1]}
                                                  className="btn btn-info"
                                                  target="_blank"
                                                >
                                                  <em className="icon ni ni-external"></em>{" "}
                                                  <span>Redirect to link</span>
                                                </a>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-add-c"></em> Assign
                          Services To{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-left">
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.didArray !== "" ? (
                              <div className="col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="did_service"
                                  name="did_service"
                                  select
                                  label="Select DID Service"
                                  value={this.state.did_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the DID service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.didArray.map((didservices) => (
                                    <option value={didservices[0]}>
                                      {didservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.didArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ marginTop: "15px" }}
                              >
                                <MutextField
                                  id="tfn_service"
                                  name="tfn_service"
                                  select
                                  label="Select TFN Service"
                                  value={this.state.tfn_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the TFN service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.tfnArray.map((tfnservices) => (
                                    <option value={tfnservices[0]}>
                                      {tfnservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.termArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ marginTop: "15px" }}
                              >
                                <MutextField
                                  id="term_service"
                                  name="term_service"
                                  select
                                  label="Select Termination Rate Service"
                                  value={this.state.term_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the termination rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.termArray.map((termservices) => (
                                    <option value={termservices[0]}>
                                      {termservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.teamsArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ marginTop: "15px" }}
                              >
                                <MutextField
                                  id="teams_service"
                                  name="teams_service"
                                  select
                                  label="Select Microsoft Teams Rate Service"
                                  value={this.state.teams_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the Microsoft Teams rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.teamsArray.map((ivrservices) => (
                                    <option value={ivrservices[0]}>
                                      {ivrservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.liveagentArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ marginTop: "15px" }}
                              >
                                <MutextField
                                  id="liveagent_service"
                                  name="liveagent_service"
                                  select
                                  label="Select Live Agent Rate Service"
                                  value={this.state.liveagent_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the Live Agent rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.liveagentArray.map(
                                    (liveagentservices) => (
                                      <option value={liveagentservices[0]}>
                                        {liveagentservices[1]}
                                      </option>
                                    )
                                  )}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.smsArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ marginTop: "15px" }}
                              >
                                <MutextField
                                  id="sms_service"
                                  name="sms_service"
                                  select
                                  label="Select SMS Rate Service"
                                  value={this.state.sms_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the SMS rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.smsArray.map((smsservices) => (
                                    <option value={smsservices[0]}>
                                      {smsservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              style={{ cursor: "pointer" }}
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSave();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAssignedServices"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em> Assigned
                          Services / Packages of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/*<ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#assigned_services"
                          >
                            <em className="icon ni ni-file-docs"></em>
                            <span>Services</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#assigned_packages"
                          >
                            <em className="icon ni ni-grid-add-c"></em>
                            <span>Packages</span>
                          </a>
                        </li>
                            </ul>*/}
                      <div className="tab-content">
                        <div className="tab-pane active" id="assigned_services">
                          <div className="col-lg-12">
                            {this.state.showServiceLoader === true ? (
                              FormLoader()
                            ) : (
                              <div
                                className="card card-bordered genericTable"
                                id="assignedServicesTable"
                              >
                                {this.state.serviceAssignedData &&
                                this.state.serviceAssignedData.length > 0 ? (
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>

                                        <th scope="col">Type</th>
                                        <th scope="col">Projection</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.serviceAssignedData.map(
                                        (service, index) => (
                                          <tr key={`ser${index}`}>
                                            <td>{service[1]}</td>

                                            <td>
                                              {service[5] === "did"
                                                ? "DID Origination"
                                                : service[5] === "tfn"
                                                ? "Toll Free Origination"
                                                : service[5] === "term"
                                                ? "DID Termination"
                                                : service[5] === "teams"
                                                ? "Microsoft Teams UCAAS"
                                                : service[5] === "ivr"
                                                ? "Voyce Admins"
                                                : service[5] === "tfn_term"
                                                ? "Toll Free Termination"
                                                : service[5] === "az_term"
                                                ? "International A-Z Termination"
                                                : service[5] === "live_agent"
                                                ? "Live Agent"
                                                : service[5] === "sms"
                                                ? "SMS Inbound / Outbound"
                                                : ""}
                                            </td>
                                            <td>
                                              {this.handleProjection(
                                                service[6]
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h6>No Assigned Service</h6>
                                      <p>
                                        Services are not assigned to this
                                        client.{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="assigned_packages">
                          <div className="col-lg-12">
                            {this.state.showServiceLoader === true ? (
                              FormLoader()
                            ) : (
                              <div
                                className="card card-bordered genericTable"
                                id="assignedPackagesTable"
                              >
                                {this.state.packagesAssignedData &&
                                this.state.packagesAssignedData.length > 0 ? (
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">
                                          Rate Per Month (Charge Monthly)
                                        </th>
                                        <th scope="col">
                                          Rate Per Month (Charge Yearly)
                                        </th>
                                        <th scope="col">Date Added</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.packagesAssignedData.map(
                                        (packages, index) => (
                                          <tr key={`ser${index}`}>
                                            <th scope="row">{packages[0]}</th>
                                            <td>
                                              $
                                              {packages[1] &&
                                              packages[1] !== "0"
                                                ? packages[1].toFixed(2)
                                                : "0.00"}
                                            </td>
                                            <td>
                                              $
                                              {packages[2] &&
                                              packages[2] !== "0"
                                                ? packages[1].toFixed(2)
                                                : "0.00"}
                                            </td>
                                            <td>
                                              {packages[3] && packages[3] !== ""
                                                ? this.format_date(packages[3])
                                                : null}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h6>No Assigned Package</h6>
                                      <p>
                                        Packages are not assigned to this
                                        client.{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/*Client Service Modal Start */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormClientServices"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em> Client
                          Notes{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <h6>Welcome Content</h6>
                              </div>
                              <div className="form-group">
                                <Editor
                                  id="client_services_welcome_content"
                                  name="client_services_welcome_content"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={
                                    this.state.client_services_welcome_content
                                  }
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                  }}
                                  onEditorChange={this.handleEditorChange}
                                />
                              </div>
                              {/*<div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <TextareaAutosize
                                  maxrows={4}
                                  name="client_services_welcome_content"
                                  id="client_services_welcome_content"
                                  aria-label=""
                                  placeholder=""
                                  value={
                                    this.state.client_services_welcome_content
                                  }
                                  onChange={this.handleChangeClientServices}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderColor: "rgba(0, 0, 0, 0.125)",
                                    borderRadius: "4px",
                                  }}
                                  variant="outlined"
                                />
                                </div>*/}
                            </div>
                            {/*<div
                              className="col-lg-12"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div>
                                <h6>Email</h6>
                              </div>
                              <div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <MutextField
                                  required
                                  id="client_services_email"
                                  name="client_services_email"
                                  type="email"
                                  value={this.state.client_services_email}
                                  onChange={this.handleChangeClientServices}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              </div>*/}
                            <div
                              className="col-lg-12"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <table className="table table-striped">
                                <tbody>
                                  <tr>
                                    <th scope="row">Client Place Holders</th>
                                    <td>
                                      <ul className="preview-list">
                                        <li className="preview-item">
                                          <span className="badge badge-outline-gray">
                                            [[Company_Name]]
                                          </span>
                                        </li>
                                        <li className="preview-item">
                                          <span className="badge badge-outline-gray">
                                            [[Company_Email]]
                                          </span>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div
                              className="form-group text-right col-lg-12"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              {" "}
                              <button
                                type="button"
                                style={{ marginRight: 5 }}
                                className="btn btn-light"
                                onClick={() => {
                                  this.setState({
                                    errorMessage: "",
                                    successMessage: "",
                                  });
                                  window
                                    .$("#modalFormClientServices")
                                    .modal("hide");
                                }}
                              >
                                {" "}
                                Cancel{" "}
                              </button>{" "}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.handleClientServicesSubmit();
                                }}
                                disabled={this.state.disabled}
                              >
                                {" "}
                                Submit{" "}
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Client Service Modal End */}
              {/* Agreement MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormClientAgreements"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-add-c"></em> Client
                          Agreements{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      {/*} {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}*/}

                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#tabItemSigned"
                                >
                                  <em className="icon ni ni-check-circle-cut"></em>
                                  <span>Signed Agreements</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#tabItemUnsigned"
                                >
                                  <em className="icon ni ni-na"></em>
                                  <span>Unsigned Agreements</span>
                                </a>
                              </li>
                            </ul>

                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="tabItemSigned"
                              >
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.isRevokeReasonActive ? (
                                  <div id="revokeReasonItems">
                                    <div className="tb-odr-id">
                                      Reason to revoke
                                    </div>
                                    <div className="card card-bordered card-preview mt-1">
                                      <TextareaAutosize
                                        maxrows={4}
                                        name="revoke_reason"
                                        id="revoke_reason"
                                        aria-label=""
                                        placeholder=""
                                        value={this.state.revoke_reason}
                                        helperText="Reason to revoke this agreement"
                                        onChange={this.handleRevokeValueChange}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          borderColor: "rgba(0, 0, 0, 0.125)",
                                          borderRadius: "4px",
                                        }}
                                        variant="outlined"
                                      />
                                    </div>
                                    <div
                                      className="form-group text-right"
                                      style={{
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        style={{ marginRight: 5 }}
                                        className="btn btn-light"
                                        onClick={() => {
                                          this.setState({
                                            isRevokeReasonActive: false,
                                            revoke_reason: "",
                                            errorMessage: "",
                                            successMessage: "",
                                          });
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          this.handleClientAgreementRevokeSubmit(
                                            this.state.revokeAgreementData
                                              .agreement_accountno,
                                            this.state.client_accountno,
                                            this.state.revoke_reason
                                          );
                                        }}
                                        disabled={this.state.disabled}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                ) : null}

                                {this.state.isApproveActive ? (
                                  <div className="row g-gs">
                                    <div className="col-md-12 mb-3">
                                      <div class="card card-bordered">
                                        <div class="card-inner">
                                          <h5 class="card-title">
                                            Approve Agreement
                                          </h5>

                                          <p class="card-text">
                                            Are you sure you want to approve
                                            this agreement?
                                          </p>

                                          <div className="col-md-12">
                                            <div className="form-group text-right">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.setState({
                                                    isApproveActive: false,
                                                    approve_agreement_accountno:
                                                      "",
                                                    approve_agreement_id: "",
                                                  })
                                                }
                                                className="btn btn-light"
                                                disabled={this.state.disabled}
                                                style={{ marginRight: "15px" }}
                                              >
                                                No
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.approveAgreement(
                                                    this.state
                                                      .approve_agreement_accountno
                                                  )
                                                }
                                                className="btn btn-success"
                                                disabled={this.state.disabled}
                                                title=""
                                              >
                                                Yes
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-date d-none d-md-inline-block">
                                            Signed Date
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-total">
                                            Approved
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-status d-none d-md-inline-block">
                                            Download
                                          </span>
                                        </th>
                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.signedData &&
                                      this.state.signedData.length > 0 ? (
                                        <>
                                          {this.state.signedData.map(
                                            (signed, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`sign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {signed.agreement_title}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-date">
                                                    {signed.signed_date &&
                                                    signed.signed_date !== ""
                                                      ? this.format_date(
                                                          signed.signed_date
                                                        )
                                                      : "---"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-status">
                                                    {signed.approved ===
                                                    "Yes" ? (
                                                      <span className="badge badge-outline-success">
                                                        Yes
                                                      </span>
                                                    ) : (
                                                      <span className="badge badge-outline-danger">
                                                        No
                                                      </span>
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-success">
                                                      Signed
                                                    </span>
                                                  </span>
                                                </td>

                                                <td>
                                                  <a
                                                    onClick={() =>
                                                      this.downloadPdf(
                                                        signed.pdf_path,
                                                        signed.agreement_title
                                                      )
                                                    }
                                                    title="Download Agreement"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <span className="badge badge-outline-primary">
                                                      <em className="icon ni ni-download"></em>
                                                    </span>
                                                  </a>
                                                </td>
                                                <td className="tb-odr-action">
                                                  <div className="dropdown">
                                                    <a
                                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                      data-toggle="dropdown"
                                                      data-offset="-8,0"
                                                    >
                                                      <em className="icon ni ni-more-h"></em>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                      <ul className="link-list-plain">
                                                        {!signed.approved ||
                                                        signed.approved ===
                                                          "No" ? (
                                                          <li>
                                                            <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="text-success"
                                                              onClick={() =>
                                                                this.setState({
                                                                  isApproveActive: true,
                                                                  approve_agreement_id:
                                                                    signed.agreement_id,
                                                                  approve_agreement_accountno:
                                                                    signed.agreement_accountno,
                                                                })
                                                              }
                                                            >
                                                              Approve
                                                            </a>
                                                          </li>
                                                        ) : null}
                                                        <li>
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.revokeAgreement(
                                                                signed.agreement_accountno
                                                              )
                                                            }
                                                            className="text-primary"
                                                          >
                                                            Revoke
                                                          </a>
                                                        </li>
                                                        {/*<li>
                                                          <a
                                                            href="#"
                                                            className="text-danger"
                                                          >
                                                            Send Email
                                                          </a>
                                            </li>*/}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="tab-pane" id="tabItemUnsigned">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>

                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>

                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.unsignedData &&
                                      this.state.unsignedData.length > 0 ? (
                                        <>
                                          {this.state.unsignedData.map(
                                            (unsigned, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`unsign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {unsigned.agreement_title}{" "}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-warning">
                                                      Unsigned
                                                    </span>
                                                  </span>
                                                </td>

                                                <td className="tb-odr-action">
                                                  {this.state.isEmailSending &&
                                                  this.state
                                                    .unsigned_email_agreement_accountno ===
                                                    unsigned.agreement_accountno ? (
                                                    <div
                                                      className="spinner-border spinner-border-sm"
                                                      role="status"
                                                    >
                                                      <span className="sr-only">
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="dropdown">
                                                      <a
                                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                        data-toggle="dropdown"
                                                        data-offset="-8,0"
                                                      >
                                                        <em className="icon ni ni-more-h"></em>
                                                      </a>
                                                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                        <ul className="link-list-plain">
                                                          <li>
                                                            <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="text-danger"
                                                              onClick={() =>
                                                                this.sendEmailUnsignedAgreement(
                                                                  unsigned.agreement_accountno,
                                                                  this.state
                                                                    .client_accountno
                                                                )
                                                              }
                                                            >
                                                              Send Email
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Agreement MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalAlertResend">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a href="#" className="close" data-dismiss="modal">
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 className="nk-modal-title">Sending!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Congratulations!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 className="nk-modal-title">Oops!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="modal-footer bg-lighter">
                      <div className="text-center w-100">
                        <a
                          href="#"
                          className="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalClientDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-card-view"></em> KYC Details
                          of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {this.state.kycErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.kycErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kycSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.kycSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key === "" ? (
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                              <div className="nk-help">
                                <div className="nk-help-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 90 90"
                                  >
                                    <rect
                                      x="5"
                                      y="7"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="25"
                                      y="27"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="15"
                                      y="17"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#fff"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <line
                                      x1="15"
                                      y1="29"
                                      x2="75"
                                      y2="29"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeMiterlimit="10"
                                      strokeWidth="2"
                                    ></line>
                                    <circle
                                      cx="53"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="60"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="67"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <rect
                                      x="22"
                                      y="39"
                                      width="20"
                                      height="20"
                                      rx="2"
                                      ry="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <circle
                                      cx="32"
                                      cy="45.81"
                                      r="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></circle>
                                    <path
                                      d="M29,54.31a3,3,0,0,1,6,0"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></path>
                                    <line
                                      x1="49"
                                      y1="40"
                                      x2="69"
                                      y2="40"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="51"
                                      x2="69"
                                      y2="51"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="57"
                                      x2="59"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="57"
                                      x2="66"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="46"
                                      x2="59"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="46"
                                      x2="66"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                  </svg>
                                </div>
                                <div className="nk-help-text">
                                  <h5>Initiate KYC Process</h5>
                                  <p className="text-soft">
                                    Register your customer with KYC to complete
                                    their profile. Click on "Initiate KYC"
                                    button. An email with KYC registration link
                                    will be sent to the customer.
                                  </p>
                                </div>
                                <div className="nk-help-action">
                                  {this.state.loading_button === false ? (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.initiateKyc();
                                      }}
                                      disabled={this.state.disabled}
                                      href="#"
                                      className="btn btn-lg btn-outline-primary"
                                    >
                                      <em className="icon ni ni-file-docs"></em>{" "}
                                      Initiate KYC
                                    </a>
                                  ) : null}
                                  {this.state.loading_button === true ? (
                                    <button
                                      className="btn btn-primary btn-lg"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Initiating... </span>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.kyc_key !== "" ? (
                        <div id="printablediv">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    KYCs /{" "}
                                    <strong className="text-primary small">
                                      {this.state.company_title}
                                    </strong>
                                  </h3>
                                  <div className="nk-block-des text-soft">
                                    <ul className="list-inline">
                                      <li>
                                        Submited At:{" "}
                                        <span className="text-base">
                                          {this.state.client_register_date}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="nk-block-head-content">
                                  <div className="btn-group">
                                    {this.state.kyc_approved === "No" ||
                                    this.state.kyc_approved === "" ? (
                                      <>
                                        <button
                                          onClick={() =>
                                            this.showApprovedCont("approve")
                                          }
                                          type="button"
                                          className="btn btn-outline-primary"
                                          disabled={this.state.disabled}
                                        >
                                          <em className="icon ni ni-check-round"></em>
                                          <span>Approve </span>
                                        </button>
                                        <button
                                          onClick={() =>
                                            this.showApprovedCont("reject")
                                          }
                                          type="button"
                                          className="btn btn-outline-primary"
                                          disabled={this.state.disabled}
                                        >
                                          <em className="icon ni ni-cross-round"></em>
                                          <span>Reject </span>
                                        </button>
                                      </>
                                    ) : this.state.kyc_approved === "Yes" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={this.state.disabled}
                                      >
                                        <em className="icon ni ni-done"></em>
                                        <span>Approved </span>
                                      </button>
                                    ) : null}
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          showSendEmail: true,
                                        });
                                      }}
                                      type="button"
                                      className="btn btn-outline-primary"
                                      disabled={this.state.disabled}
                                    >
                                      <em className="icon ni ni-emails"></em>
                                      <span>Send Email </span>
                                    </button>
                                    {this.state.completed_percentage ===
                                    "100" ? (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.downloadKycPdf(
                                            this.state.kyc_key
                                          );
                                        }}
                                        className="btn btn-outline-primary"
                                      >
                                        <em className="icon ni ni-download"></em>
                                        <span>Download PDF</span>
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.showSendEmail ? (
                              <div id="showSendEmailItems">
                                <div className="form-group mt-1">
                                  <MutextField
                                    required
                                    id="kyc_email_address"
                                    name="kyc_email_address"
                                    type="text"
                                    label="Email Address"
                                    defaultValue={this.state.kyc_email_address}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div
                                  className="form-group text-right"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{ marginRight: 5 }}
                                    className="btn btn-light"
                                    onClick={() => {
                                      this.setState({
                                        showSendEmail: false,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.email_kyc_Details();
                                    }}
                                    disabled={this.state.disabled}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {this.state.showKycApproved ? (
                              <div id="showSendEmailItemss">
                                <h5>
                                  {this.state.showApprovedAction === "approve"
                                    ? "Approve KYC"
                                    : this.state.showApprovedAction === "reject"
                                    ? "Reject KYC"
                                    : null}
                                </h5>
                                <div className="form-group mt-1">
                                  <MutextField
                                    required
                                    id="kyc_reason"
                                    name="kyc_reason"
                                    type="text"
                                    label="Reason"
                                    defaultValue={this.state.kyc_reason}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div
                                  className="form-group text-right"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{ marginRight: 5 }}
                                    className="btn btn-light"
                                    onClick={() => {
                                      this.setState({
                                        showKycApproved: false,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.submit_client_kyc_approval();
                                    }}
                                    disabled={this.state.disabled}
                                  >
                                    Submit
                                  </button>
                                </div>
                                <div className="example-alert mb-4">
                                  <div className="alert alert-light">
                                    {this.state.showApprovedAction ===
                                    "approve" ? (
                                      <>
                                        <h5>Approval Classifications</h5>
                                        <ol className="list">
                                          <li>
                                            <strong>Approval A:</strong> This
                                            indicates a direct, authenticated
                                            relationship between the Approver
                                            and the customer or the Agent's
                                            customer. It signifies that the
                                            Approver vouches for the required
                                            information being either already
                                            available or known to them.
                                          </li>
                                          <li>
                                            <strong>Approval B:</strong> This
                                            signifies that the Approver has
                                            obtained information about the
                                            customer via the Agent or Sub-Agent.
                                            The approval is based on the
                                            authenticity of this information or
                                            upon the Agent's/Sub-Agent's
                                            request. However, it implies the
                                            need for further information
                                            verification or that verification is
                                            in progress.
                                          </li>
                                          <li>
                                            <strong>Approval C:</strong> This
                                            approval is solely based on an
                                            internal request within the office.
                                            It doesn't indicate any direct or
                                            indirect relationship with the
                                            customer or any substantial
                                            information about the customer's
                                            identity.
                                          </li>
                                        </ol>
                                      </>
                                    ) : this.state.showApprovedAction ===
                                      "reject" ? (
                                      <>
                                        <h5>Rejection Classifications</h5>
                                        <ol className="list">
                                          <li>
                                            <strong>Approval A:</strong> This
                                            indicates a direct, authenticated
                                            relationship between the Approver
                                            and the customer or the Agent's
                                            customer. It signifies that the
                                            Approver vouches for the required
                                            information being either already
                                            available or known to them.
                                          </li>
                                          <li>
                                            <strong>Approval B:</strong> This
                                            signifies that the Approver has
                                            obtained information about the
                                            customer via the Agent or Sub-Agent.
                                            The approval is based on the
                                            authenticity of this information or
                                            upon the Agent's/Sub-Agent's
                                            request. However, it implies the
                                            need for further information
                                            verification or that verification is
                                            in progress.
                                          </li>
                                          <li>
                                            <strong>Approval C:</strong> This
                                            approval is solely based on an
                                            internal request within the office.
                                            It doesn't indicate any direct or
                                            indirect relationship with the
                                            customer or any substantial
                                            information about the customer's
                                            identity.
                                          </li>
                                        </ol>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="nk-block-head">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title title">
                                  Profile Completed{" "}
                                  <span className="text-primary">
                                    ({this.state.completed_percentage}%)
                                  </span>
                                </h5>
                                <div className="card card-bordered card-preview">
                                  <div className="card-inner">
                                    <div className="progress progress-lg">
                                      <div
                                        className="progress-bar"
                                        data-progress={
                                          this.state.completed_percentage
                                        }
                                        style={{
                                          width: `${this.state.completed_percentage}%`,
                                        }}
                                      >
                                        {this.state.completed_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.kyc_details &&
                          this.state.kyc_details.length > 0 ? (
                            <div id="accordion" className="accordion">
                              {this.state.kyc_details.map((kyc, i) => (
                                <div
                                  className="accordion-item"
                                  key={`kycid${i}`}
                                >
                                  <a
                                    href="#"
                                    className={this.handle_accordion_head(i)}
                                    data-toggle="collapse"
                                    data-target={`#accordion-item-${i}`}
                                    style={{ background: "rgb(245, 246, 250)" }}
                                  >
                                    <h6 className="title">
                                      {kyc.steps_information.step_title}
                                      <br />
                                      <small>
                                        {kyc.steps_information.step_description}
                                      </small>
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className={this.handle_accordion_body(i)}
                                    id={`accordion-item-${i}`}
                                    data-parent="#accordion"
                                  >
                                    <div className="accordion-inner">
                                      {kyc.steps_data &&
                                      kyc.steps_data.length > 0 ? (
                                        <div className="card">
                                          <ul className="data-list is-compact">
                                            {kyc.steps_data.map(
                                              (kyc_steps, i) => (
                                                <li
                                                  className="data-item"
                                                  key={`step${i}`}
                                                >
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      {kyc_steps.field_title}
                                                    </div>
                                                    <div className="data-value">
                                                      {kyc_steps.field_type ===
                                                      "image" ? (
                                                        <img
                                                          src={
                                                            kyc_steps.field_value
                                                          }
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        />
                                                      ) : (
                                                        kyc_steps.field_value
                                                      )}
                                                    </div>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="col-lg-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* CREDIT APPLICATIONS MODAL START */}
              <div
                className="modal fade"
                tabindex="-1"
                id="modalCreditApplications"
              >
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-coin"></em> Credit
                        Applications
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.creditErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.creditErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.creditSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.creditSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.processing_request === true ? (
                        <div className="example-alert mb-3">
                          <div className="alert alert-light">
                            <div
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>{" "}
                            Please wait while we are processing your request.
                          </div>
                        </div>
                      ) : null}

                      {this.state.rejection_container === true ? (
                        <div className="col-md-12 mb-3">
                          <h5>Rejection Reason</h5>
                          <div className="">
                            <div className="form-group mt-1">
                              <MutextField
                                required
                                id="rejection_reason"
                                name="rejection_reason"
                                type="text"
                                label="Reason"
                                defaultValue={this.state.rejection_reason}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div
                              className="form-group text-right"
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              <button
                                type="button"
                                style={{ marginRight: 5 }}
                                className="btn btn-light"
                                onClick={() => {
                                  this.setState({
                                    rejection_container: false,
                                  });
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.rejectCreditApplication();
                                }}
                                disabled={this.state.disabled}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="card card-bordered"
                        id="creditApplicationTable"
                      >
                        {this.state.credit_applications &&
                        this.state.credit_applications.length > 0 ? (
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Approved date</th>
                                <th scope="col">Rejected Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.credit_applications.map(
                                (applications, index) => (
                                  <tr key={`cr${index}`}>
                                    <th scope="row">{applications[0]}</th>
                                    <td>{this.format_date(applications[1])}</td>
                                    <td>
                                      {applications[2] && applications[2] !== ""
                                        ? this.format_date(applications[2])
                                        : "---"}
                                    </td>
                                    <td>
                                      {applications[3] && applications[3] !== ""
                                        ? this.format_date(applications[3])
                                        : "---"}
                                    </td>
                                    <td>
                                      {applications[4] === "pending" ? (
                                        <span className="badge badge-dot badge-warning">
                                          Pending
                                        </span>
                                      ) : applications[4] === "approved" ? (
                                        <span className="badge badge-dot badge-success">
                                          Approved
                                        </span>
                                      ) : applications[4] === "rejected" ? (
                                        <span className="badge badge-dot badge-danger">
                                          Rejected
                                        </span>
                                      ) : null}
                                    </td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                          <ul className="link-list-plain">
                                            <li>
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.downloadCreditAppPdf(
                                                    applications[6]
                                                  )
                                                }
                                              >
                                                View PDF
                                              </a>
                                            </li>
                                            {applications[4] === "pending" ? (
                                              <li>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    this.setState({
                                                      application_no:
                                                        applications[0],
                                                      rejection_container: true,
                                                    });
                                                  }}
                                                >
                                                  Reject
                                                </a>
                                              </li>
                                            ) : null}
                                            {applications[4] === "pending" ? (
                                              <li>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.approveCreditApplication(
                                                      applications[0]
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </a>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h6>No Credit Application</h6>
                              <p>
                                Credit applications are not available for this
                                client at the moment.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer text-right d-flex justify-content-end">
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-light"
                        data-dismiss="modal"
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* CREDIT APPLICATIONS MODAL END */}

              {/* New Client Modal Start */}
              <div className="modal fade" tabindex="-1" id="modalAddNewClient">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-users"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Add New Client
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <div className="row g-4">
                          {this.state.clientErrorMessage !== "" ? (
                            <div className="col-md-12 mb-3">
                              <div
                                className="example-alert example-alert-revoke"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.clientErrorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.clientSuccessMessage !== "" ? (
                            <div className="col-md-12 mb-3">
                              <div
                                className="example-alert example-alert-revoke"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.clientSuccessMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-group">
                                <MutextField
                                  id="select_agent"
                                  name="select_agent"
                                  select
                                  label="Select Agent"
                                  value={this.state.select_agent}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Kindly choose an agent to whom we will assign this client."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="sets100" value=""></option>
                                  {this.state.AgentsList &&
                                  this.state.AgentsList.length > 0 ? (
                                    <>
                                      {this.state.AgentsList.map(
                                        (agent_list, index) => (
                                          <option
                                            key={`sets${index}`}
                                            value={agent_list[7]}
                                          >
                                            {agent_list[2]} ({agent_list[1]})
                                          </option>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </MutextField>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="client_name"
                                  name="client_name"
                                  type="text"
                                  label="Full Name"
                                  defaultValue={this.state.client_name}
                                  inputProps={{ maxLength: 64 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^a-zA-Z0-9 ]/gi,
                                      ""
                                    );
                                  }}
                                  helperText="Full name of the invite recipient."
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="client_email"
                                  name="client_email"
                                  type="text"
                                  label="Email Address"
                                  defaultValue={this.state.client_email}
                                  helperText="Email address of the invite recipient."
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="button"
                              onClick={() => this.saveNewClient()}
                              className="btn btn-primary"
                              disabled={this.state.disabled}
                            >
                              <span>Send Invite</span>{" "}
                              <em className="icon ni ni-emails"></em>
                            </button>
                          </div>
                          <div className="col-md-12">
                            <div className="example-alert">
                              <div className="alert alert-light">
                                <ul className="list">
                                  <li>
                                    An email will be dispatched to the provided
                                    email address.
                                  </li>
                                  <li>
                                    This email will contain a secure, tokenized
                                    link for client registration.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* New Client Modal End */}
              {/* UPDATE CLIENT MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalUpdateClient">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Client Profile</h5>
                      <a
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorClientMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorClientMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successClientMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successClientMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Company Name"
                              error={this.state.errorClientCompName}
                              id="client_comp_name"
                              name="client_comp_name"
                              value={this.state.client_comp_name}
                              placeholder="Company Name"
                              change={this.handleChange}
                              type="text"
                              maxLength={100}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Full Name"
                              error={this.state.errorClientName}
                              id="client_update_name"
                              name="client_update_name"
                              value={this.state.client_update_name}
                              placeholder="Full Name"
                              change={this.handleChange}
                              maxLength={100}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Title"
                              error={this.state.errorClientTitle}
                              id="client_title"
                              name="client_title"
                              value={this.state.client_title}
                              placeholder="Title"
                              change={this.handleChange}
                              maxLength={30}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Email"
                              type="text"
                              error={this.state.errorClientEmail}
                              id="client_update_email"
                              name="client_update_email"
                              value={this.state.client_update_email}
                              placeholder="Enter email address"
                              change={this.handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Phone"
                              type="text"
                              error={this.state.errorClientPhone}
                              id="client_phone"
                              name="client_phone"
                              value={this.state.client_phone}
                              placeholder="Enter phone"
                              change={this.handleChange}
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Address"
                              type="text"
                              error={this.state.errorClientAddress}
                              id="client_address"
                              name="client_address"
                              value={this.state.client_address}
                              placeholder="Enter address"
                              change={this.handleChange}
                              maxLength={100}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label
                              className="text-dark"
                              style={{
                                marginBottom: "0.5rem",
                                fontWeight: "600",
                              }}
                            >
                              Country
                            </label>
                            <Select
                              id="client_country"
                              name="client_country"
                              className="customCountrySelect"
                              options={this.countryOptions}
                              value={{
                                label: this.state.client_country,
                                value: this.state.client_country,
                              }}
                              onChange={this.handleChangeCountry}
                              placeholder="Select country"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="City"
                              type="text"
                              error={this.state.errorClientCity}
                              id="client_city"
                              name="client_city"
                              value={this.state.client_city}
                              placeholder="Enter city"
                              change={this.handleChange}
                              maxLength={32}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="State"
                              type="text"
                              error={this.state.errorClientState}
                              id="client_state"
                              name="client_state"
                              value={this.state.client_state}
                              placeholder="Enter state"
                              change={this.handleChange}
                              maxLength={3}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Zip Code"
                              type="text"
                              error={this.state.errorClientZip}
                              id="client_zip"
                              name="client_zip"
                              value={this.state.client_zip}
                              placeholder="Enter zip code"
                              change={this.handleChange}
                              maxLength={9}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Password"
                              type="password"
                              error={this.state.errorClientPass}
                              id="client_password"
                              name="client_password"
                              value={this.state.client_password}
                              placeholder="Enter password"
                              change={this.handleChange}
                              maxLength={30}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Confirm Password"
                              type="password"
                              error={this.state.errorClientPass}
                              id="client_confirm_password"
                              name="client_confirm_password"
                              value={this.state.client_confirm_password}
                              placeholder="Enter confirm password"
                              change={this.handleChange}
                              maxLength={30}
                            />
                          </div>
                        </div>
                        <div
                          className="custom-control custom-control-lg custom-checkbox col-lg-12"
                          style={{ padding: 0, marginLeft: "3rem" }}
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck2"
                              onChange={() => {
                                const isChecked = !this.state.notifyCheckBox;
                                this.setState({ notifyCheckBox: isChecked });
                                console.log(
                                  "Notify Checkbox Checked:",
                                  isChecked
                                );
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck2"
                            >
                              Notify Client
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">&nbsp;</div>
                        <div style={{ clear: "both" }}></div>
                        {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-lg"
                            onClick={() => {
                              this.formUpdateClient();
                            }}
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                              float: "right",
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* UPDATE CLIENT MODAL END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg nk-block-clientCustom">
                      {/* START TABLE HEAD */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          accountnoFromParams={accountnoFromParams}
                          log_type={log_type}
                          title="All Clients"
                        />
                      )}

                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Role Assignment Modal START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalAgentAssignRole"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-grid-alt"></em>Client
                              roles{" "}
                              <sup>
                                <small className="text-soft">
                                  {this.state.rolesAgentName}
                                </small>
                              </sup>
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body text-left">
                          <div className="nk-modal">
                            <div className="nk-modal-text">
                              {this.state.agentRoleLoader === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.errorMessageAgentRole !== "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>
                                            {this.state.errorMessageAgentRole}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.successMessageAgentRole !== "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {this.state.successMessageAgentRole}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="card card-bordered card-preview">
                                    <table className="table table-orders">
                                      <thead className="tb-odr-head">
                                        <tr className="tb-odr-item">
                                          <th
                                            width="35%"
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                            }}
                                          >
                                            <span className="tb-odr-action">
                                              Roles
                                            </span>
                                          </th>
                                          <th width="65%">
                                            <span className="tb-odr-action">
                                              Sub Roles
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="tb-odr-body">
                                        {this.state.assignRoleData ? (
                                          <>
                                            {this.state.assignRoleData.map(
                                              (role, index) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`role${index}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.changedRolesRights.some(
                                                            (item) =>
                                                              item.id ===
                                                                role.id &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .changedRolesRights
                                                          }
                                                          disabled={
                                                            role.parent_role_id ===
                                                            1
                                                          }
                                                          name={role.id.toString()}
                                                          id={`role-${role.parent_role_id}`}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={role.alt}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    {this.state.assignSubRoleData
                                                      .filter((subRole) => {
                                                        return (
                                                          subRole.parent_role_id ===
                                                          role.parent_role_id
                                                        );
                                                      })
                                                      .map(
                                                        (subRole, subIndex) => (
                                                          <div
                                                            key={`subRole${subIndex}`}
                                                          >
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={this.state.changedSubRolesRights.some(
                                                                    (item) =>
                                                                      item.id ===
                                                                        subRole.id &&
                                                                      item.isChecked
                                                                  )}
                                                                  onChange={
                                                                    this
                                                                      .changedSubRolesRights
                                                                  }
                                                                  name={subRole.id.toString()}
                                                                  id={`subrole-${subRole.parent_role_id}`}
                                                                  color="primary"
                                                                />
                                                              }
                                                              style={{
                                                                marginBottom:
                                                                  "0px",
                                                              }}
                                                              label={
                                                                subRole.alt
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                  </td>
                                                </tr>
                                              )
                                            )}

                                            {this.state.assignSubRoleData
                                              .filter((subRole) => {
                                                // Check if parent_role_id exists in assignRoleData.parent_id
                                                return !this.state.assignRoleData.some(
                                                  (role) =>
                                                    role.parent_role_id ===
                                                    subRole.parent_role_id
                                                );
                                              })
                                              .map((subRole, subIndex) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`subRole${subIndex}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    {/* Add your checkbox logic for subRoles here */}
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.changedSubRolesRights.some(
                                                            (item) =>
                                                              item.id ===
                                                                subRole.id &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .changedSubRolesRights
                                                          }
                                                          name={subRole.id.toString()}
                                                          id={`subrole-${subRole.parent_role_id}`}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={subRole.alt}
                                                    />
                                                  </td>
                                                </tr>
                                              ))}
                                          </>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="nk-modal-action  text-right">
                                    <a
                                      href="#"
                                      className="btn btn-lg btn-mw btn-primary "
                                      onClick={() => {
                                        this.handleSubmitRoleCheckbox();
                                      }}
                                      disabled={this.state.disableRoleBtn}
                                    >
                                      Assign Roles
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Role Assignment Modal END */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Clients));
