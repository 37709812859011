import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { COMPANY_NAME } from "../../../config/config";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail } from "../../../config/utility";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";

import {
  listSubClientRights,
  addSubCLientRights,
} from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitleBreadCrumb from "../ExtraComponents/PageTitleBreadCrumb";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { Editor } from "@tinymce/tinymce-react";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
} from "./../../../config/config";
const auth = new HelperClass();
class Subclients extends Component {
  constructor() {
    super();
    this.state = {
      allRights: [],
      assignedMainRights: [],
      assignedSubRights: [],
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      disabled: false,
      client_accountno: "",
      client_parent: "",
      main_menu_id: "",
      sub_menu_id: "",
    };
  }

  async componentDidMount() {
    let sub_id = atob(this.props.props.match.params.sub_id);
    let client_accountno_arr = sub_id.split("___");
    let client_accountno = client_accountno_arr[0];
    let client_parent = client_accountno_arr[1];
    this.setState({
      client_accountno: client_accountno,
      client_parent: client_parent,
    });
    const clientsResponce = await listSubClientRights(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      client_parent
    );
    console.log("Client Rights Data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        allRights: clientsResponce.data.allRights,
        assignedMainRights: clientsResponce.data.assignedMainRights,
        assignedSubRights: clientsResponce.data.assignedSubRights,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    let { client_accountno, client_parent } = this.state;
    var sub_selected_menus = [];
    var main_selected_menus = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        sub_selected_menus = $(
          "input:checkbox.vdrSelectedSub" + this.value + ":checked"
        )
          .map(function () {
            return this.value;
          })
          .get();
        console.log("sub_selected_menus: ", sub_selected_menus);
        let insideArray = {
          main_id: this.value,
          client_accountno: client_accountno,
          submenus: sub_selected_menus,
        };
        return insideArray;
      })
      .get();

    console.log("main_selected_services: ", main_selected_menus);

    const servicesResponce = await addSubCLientRights(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      client_parent,
      JSON.stringify(main_selected_menus)
    );
    console.log("Add Rights Result: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "client_not_found"
    ) {
      //window.location.replace("/error");
      this.setState({
        successMessage: "",
        disabled: false,
        errorMessage: "Client not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Rights added successfully.",
        errorMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        successMessage: "",
        disabled: false,
        errorMessage: "There is some error while adding the rights.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        disabled: false,
        errorMessage: "",
      });
    }, 4000);
  };

  render() {
    const breadcrumb_arr = [
      {
        title: "Clients",
        url: "/clients",
      },
      {
        title: "Sub Clients",
        url: "/clients/subclients/" + this.state.client_parent,
      },
      {
        title: "Sub Client Rights",
        url: "",
      },
    ];
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitleBreadCrumb
                name={breadcrumb_arr}
                icon="icon ni ni-users"
              />
              {/* PAGE TITLE END */}

              <div class="nk-block">
                <div class="nk-block-head nk-block-head-sm">
                  <div class="nk-block-head-content">
                    <div class="nk-block-des">
                      <p>
                        These permissions allow users to access and view
                        information, data, or content within the system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div class="card card-bordered p-2">
                        {this.state.errorMessage !== "" ? (
                          <>
                            <div
                              className="example-alert example-alert-revoke"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}fvfdvv</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div
                            className="example-alert example-alert-revoke"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div id="no-more-tables-new">
                          <table class="table table-bordered">
                            <thead class="thead-light">
                              <tr>
                                <th class="tb-col-os">
                                  <span class="overline-title">Main Menu</span>
                                </th>
                                <th class="tb-col-ip">
                                  <span class="overline-title">Sub Menu</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allRights &&
                              this.state.allRights.length > 0 ? (
                                <>
                                  {this.state.allRights.map((mainRights, i) => (
                                    <tr key={`menu${i}`}>
                                      <td class="tb-col-os">
                                        <div className="custom-control custom-control custom-checkbox align-switch-div">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input vdrSelected"
                                            id={`cp-main-menu${i}`}
                                            name="main_menu_id"
                                            defaultChecked={
                                              this.state.assignedMainRights &&
                                              this.state.assignedMainRights
                                                .length > 0
                                                ? this.state.assignedMainRights.includes(
                                                    mainRights.link_id
                                                  )
                                                : null
                                            }
                                            value={mainRights.link_id}
                                            onChange={this.handleChange}
                                            required=""
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`cp-main-menu${i}`}
                                          >
                                            {mainRights.alt}
                                          </label>
                                        </div>
                                      </td>
                                      <td class="tb-col-ip">
                                        {mainRights.submenu &&
                                        mainRights.submenu.length > 0 ? (
                                          <>
                                            {mainRights.submenu.map(
                                              (subRights, index) => (
                                                <div
                                                  className="col-md-6 col-lg-6 mb-2 inner_column"
                                                  key={`submenu${index}`}
                                                >
                                                  <div className="custom-control custom-control custom-checkbox align-switch-div">
                                                    <input
                                                      type="checkbox"
                                                      className={`custom-control-input vdrSelectedSub${mainRights.link_id}`}
                                                      id={`cp-sub-menu${index}`}
                                                      name="sub_menu_id"
                                                      defaultChecked={
                                                        this.state
                                                          .assignedSubRights &&
                                                        this.state
                                                          .assignedSubRights
                                                          .length > 0
                                                          ? this.state.assignedSubRights.includes(
                                                              subRights.link_id
                                                            )
                                                          : null
                                                      }
                                                      value={subRights.link_id}
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      required=""
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`cp-sub-menu${index}`}
                                                    >
                                                      {subRights.alt}
                                                    </label>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                        <div className="nk-kycfm-action m-3 text-right">
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={this.state.disabled}
                            onClick={this.formSave}
                          >
                            <em className="icon ni ni-plus-round"></em>
                            Save Rights
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subclients);
