import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addSubUser } from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "@tinymce/tinymce-react";

const auth = new HelperClass();

class Adduser extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      agent_email: auth.getEmail(),
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: false,
      errorPass: false,
      errorcPass: false,
      errorName: false,
      errorMessage: "",
      errorCompName: false,
      errorTitle: false,
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: false,
      disableResendBtn: false,
      phone: "",
      errorPhone: false,
      fieldsHide: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {}

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange: ", name + " " + value);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  fieldValidation = () => {
    let { name, email, password, confirm_password, phone, comp_name, title } =
      this.state;

    if (comp_name === "") {
      this.setState({
        errorCompName: true,
        errorMessage: "Company Name is required.",
        errorcPass: false,
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorCompName: false,
        errorName: true,
        errorMessage: "Full Name is required.",
        errorcPass: false,
      });
      return false;
    } else if (title === "") {
      this.setState({
        errorCompName: false,
        errorName: false,
        errorTitle: true,
        errorMessage: "Title is required.",
        errorcPass: false,
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: true,
        errorName: false,
        errorTitle: false,
        errorMessage: "Email is required.",
        errorcPass: false,
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: true,
        errorEmail: false,
        errorMessage: "Phone is required.",
        errorTitle: false,
        errorcPass: false,
      });
      return false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: true,
        errorEmail: false,
        errorMessage: "Invalid phone format.",
        errorcPass: false,
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: true,
        errorName: false,
        errorMessage: "Invalid email format.",
        errorcPass: false,
      });
      return false;
    } else if (password === "") {
      this.setState({
        errorPass: true,
        errorEmail: false,
        errorcPass: false,
        errorPhone: false,
        errorMessage: "Password is required.",
      });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPass: true,
        errorEmail: false,
        errorcPass: false,
        errorPhone: false,
        errorMessage: "Use 8 characters or more for your password",
      });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorPass: false,
        errorcPass: true,
        errorEmail: false,
        errorPhone: false,
        errorMessage: "Confirm password is required.",
      });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorPass: false,
        errorcPass: true,
        errorEmail: false,

        errorPhone: false,
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPass: false,
        errorcPass: true,
        errorEmail: false,

        errorPhone: false,
        errorMessage: "Password and confirm password does not match.",
      });
      return false;
    } else {
      this.setState({
        errorcPass: false,
        errorPass: false,
        errorEmail: false,
        errorMessage: false,
        errorConfirm: false,
        errorPhone: false,
        disabled: true,
      });
    }
    return true;
  };

  formSave = async () => {
    let { name, email, password, confirm_password, phone, comp_name, title } =
      this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addSubUser(
        auth.getAccount(),
        auth.getToken(),
        name,
        email,
        password,
        phone,
        comp_name,
        title
      );
      console.log("ADD RES: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "Account Already Exists"
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "Email Already Exists",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "Success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Sub user added successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/sub_users");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
      }
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>

                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/sub_users")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Sub Users
                  </Link>
                  <Typography color="textPrimary" className="">
                    New Sub User
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add Sub User
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Company Name"
                                id="comp_name"
                                name="comp_name"
                                value={this.state.comp_name}
                                placeholder="Company name"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorCompName}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Full Name"
                                id="name"
                                name="name"
                                inputProps={{ maxLength: 100 }}
                                defaultValue={this.state.name}
                                placeholder="Full Name"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorName}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Title"
                                id="title"
                                name="title"
                                inputProps={{ maxLength: 100 }}
                                defaultValue={this.state.title}
                                placeholder="Title"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorTitle}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Email"
                                type="text"
                                error={this.state.errorEmail}
                                id="email"
                                name="email"
                                defaultValue={this.state.email}
                                inputProps={{ maxLength: 250 }}
                                placeholder="Enter your email address"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Phone"
                                type="text"
                                id="phone"
                                name="phone"
                                inputProps={{ maxLength: 14 }}
                                defaultValue={this.state.phone}
                                placeholder="Enter your phone"
                                onChange={this.handleChange}
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorPhone}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Password"
                                type="password"
                                id="password"
                                name="password"
                                inputProps={{ maxLength: 100 }}
                                defaultValue={this.state.password}
                                placeholder="Enter your password"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorPass}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                label="Confirm Password"
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                inputProps={{ maxLength: 100 }}
                                defaultValue={this.state.confirm_password}
                                placeholder="Confirm your password"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorcPass}
                              />
                            </div>
                          </div>

                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <a
                                onClick={() =>
                                  this.addMenuHistory("/sub_users")
                                }
                                className="btn btn-lg btn-light"
                                disabled={this.state.disabled}
                                style={{ marginRight: "15px" }}
                              >
                                <em className="icon ni ni-step-back"></em>
                                Back
                              </a>
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save User
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Adduser);
