import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAgentAgreements,
  deleteAgreement,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const auth = new HelperClass();

class Agentagreement extends Component {
  constructor() {
    super();
    this.state = {
      agreementsData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      title: "",
      name: "",
      logo: "",
      agent_email: auth.getEmail(),
      delete_id: "",
      agent_password: "",
      c_password: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      dnsList: [],
      a_ip: "",
      is_verified: 0,
      columns: [
        { name: "Title" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.signupText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.typeText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Date Added" },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/agents/editagreement/" +
                                        tableMeta.rowData[5]
                                    )
                                  }
                                  title="Edit Agreement"
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Agreement</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[5])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Agreement</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "Archived",
          options: {
            display: "false",
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
      ],
      tableData: [],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listAgentAgreements(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreementsData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[1];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  statusText = (value, tableMeta) => {
    let is_status = tableMeta.rowData[1];
    let is_archived = tableMeta.rowData[6];
    if (is_status === 1 && is_archived === 0) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    }
  };

  signupText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[2];
    if (is_signup === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  typeText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[3];
    if (is_signup === "esign") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-edit-fill"></em> E-Signature
        </span>
      );
    } else if (is_signup === "accept") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-done"></em> Acceptance only
        </span>
      );
    }
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[2];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteAgreement = async () => {
    let agreement_id = this.state.delete_id;
    const agreementResponce = await deleteAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id
    );

    console.log("status: ", agreementResponce.data);
    //$("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessaged: agreementResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        errorMessaged: "",
        successMessaged: "Agreement deleted successfully.",
        disabled: false,
      });
      //this.componentDidMount();
      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Channel Partner Agreements"
                    icon="icon ni ni-file-docs"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Link
                          color="inherit"
                          onClick={() =>
                            this.addMenuHistory("/agents/addagreement")
                          }
                          className="btn btn-primary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#fff",
                          }}
                        >
                          <em class="icon ni ni-plus-round"></em>
                          &nbsp;Add New Agreement
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  {/* START DATATABLE */}
                  {this.state.tableLoader === true ? (
                    tableLoader()
                  ) : (
                    <DataTable
                      columns={this.state.columns}
                      tableData={this.state.agreementsData}
                      title="Channel Partner Agreements"
                    />
                  )}

                  {/* END TABLE */}
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agentagreement);
