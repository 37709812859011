import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import $ from "jquery";

import { listSubClients } from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";

import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitleBreadCrumb from "../ExtraComponents/PageTitleBreadCrumb";
import HelperClass from "./../../../config/helperClass";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  ListSubClientAssignRoles,
  editSubClientAssignRole,
} from "./../../../config/client_role_api_calls";

const auth = new HelperClass();
class Subclients extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      teamsArray: [],
      tfnTermArray: [],
      azTermArray: [],
      cnamArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      cnam_service: "",
      liveagent_service: "",

      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      emptyService: 0,
      all_client_data: [],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      client_accountno: "",
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],

      columnServices: [
        { name: "NAME" },
        { name: "COMPANY" },
        {
          name: "PHONE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatUsPhoneNumber(value)}</div>;
            },
          },
        },
        { name: "EMAIL" },

        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subclientAssignRole(value, tableMeta)
                                  }
                                  title="Assign Role"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Role</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    let client_accountno = this.props.props.match.params.id;
    this.setState({
      client_accountno: client_accountno,
    });
    const clientsResponce = await listSubClients(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );

    console.log("Client Data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        all_client_data: clientsResponce.data.all_data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  }

  subclientAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[6];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      subclientRoleLoader: true,
      subdisableRoleBtn: false,
      subdisableSubRoleBtn: false,
      rolesSubClientName:
        tableMeta.rowData[0] + " (" + tableMeta.rowData[1] + ")",
    });
    window.$("#modalSubClientAssignRole").modal("show");
    this.getSubClientRoleData(agentId, agentEmail);
  };

  getSubClientRoleData = async (agentId, agentEmail) => {
    const listSubClientAssignRoles = await ListSubClientAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubAgentRoles Res: ", listSubClientAssignRoles.data.data);
    if (
      listSubClientAssignRoles.data.status === 403 ||
      listSubClientAssignRoles.data.errors === "authentication missing" ||
      listSubClientAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubClientAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubClientAssignRoles.data.status === 200 &&
      listSubClientAssignRoles.data.message === "success"
    ) {
      this.setState({
        subassignRoleData: listSubClientAssignRoles.data.data.roles,
        subassignSubRoleData: listSubClientAssignRoles.data.data.subroles,
        subclientRoleLoader: false,
      });

      const initialActiveRoles = this.state.subassignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          id: role.id,
          isChecked: true,
        }));

      const initialActiveSubRoles = this.state.subassignSubRoleData
        .filter((subrole) => subrole.isactive === 1)
        .map((subrole) => ({
          id: subrole.id,
          isChecked: true,
        }));
      this.setState({
        subchangedRolesRights: initialActiveRoles,
        subchangedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  handleSubmitRoleCheckboxSub = async () => {
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subdisableRoleBtn: true,
    });
    const RolesRights = this.state.subchangedRolesRights;
    const SubRolesRights = this.state.subchangedSubRolesRights;
    //console.log("RolesRights ", RolesRights);
    //console.log("SubRolesRights ", SubRolesRights);

    const saveResponce = await editSubClientAssignRole(
      auth.getAccount(),
      auth.getToken(),
      RolesRights,
      SubRolesRights
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      // window.location.replace("/error");
      this.setState({
        errorMessageSubClientRole:
          "There is some error while updating the Sub Client Role.",
        successMessageSubClientRole: "",
        disableRoleBtn: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageSubClientRole: "",
        successMessageSubClientRole: "Sub CLient Role updated successfully.",
        disableRoleBtn: false,
      });
      setTimeout(() => {
        //this.props.props.history.push("/agent_roles");
        window.$("#modalSubClientAssignRole").modal("hide");
      }, 3000);
    } else {
      this.setState({
        errorMessageSubClientRole: saveResponce.data.message,
        successMessageSubClientRole: "",
        subdisableRoleBtn: false,
      });
      //window.location.replace("/error");
    }
  };

  subchangedRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedRolesRights } = this.state;

    const index = subchangedRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedChangedRolesRights = [...subchangedRolesRights];
      updatedChangedRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedRolesRights: updatedChangedRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedRolesRights: [
          ...prevState.subchangedRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  subchangedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedSubRolesRights } = this.state;

    const index = subchangedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...subchangedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedSubRolesRights: [
          ...prevState.subchangedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  addMenuHistoryLeft = (link) => {
    this.props.props.history.push(link);
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
  };

  render() {
    const breadcrumb_arr = [
      {
        title: "Clients",
        url: "/clients",
      },
      {
        title: "Sub Clients",
        url: "",
      },
    ];
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitleBreadCrumb
                name={breadcrumb_arr}
                icon="icon ni ni-users"
              />
              {/* PAGE TITLE END */}
              <div class="modal fade" tabindex="-1" id="modalAlertResend">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal">
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-body modal-body-lg text-center">
                      <div class="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 class="nk-modal-title">Sending!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 class="nk-modal-title">Congratulations!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 class="nk-modal-title">Oops!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div class="modal-footer bg-lighter">
                      <div class="text-center w-100">
                        <a
                          href="#"
                          class="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalClientDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-card-view"></em> Extra
                          Details of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {this.state.kycErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.kycErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kycSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.kycSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key === "" ? (
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                              <div className="nk-help">
                                <div className="nk-help-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 90 90"
                                  >
                                    <rect
                                      x="5"
                                      y="7"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="25"
                                      y="27"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="15"
                                      y="17"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#fff"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <line
                                      x1="15"
                                      y1="29"
                                      x2="75"
                                      y2="29"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeMiterlimit="10"
                                      strokeWidth="2"
                                    ></line>
                                    <circle
                                      cx="53"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="60"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="67"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <rect
                                      x="22"
                                      y="39"
                                      width="20"
                                      height="20"
                                      rx="2"
                                      ry="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <circle
                                      cx="32"
                                      cy="45.81"
                                      r="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></circle>
                                    <path
                                      d="M29,54.31a3,3,0,0,1,6,0"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></path>
                                    <line
                                      x1="49"
                                      y1="40"
                                      x2="69"
                                      y2="40"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="51"
                                      x2="69"
                                      y2="51"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="57"
                                      x2="59"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="57"
                                      x2="66"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="46"
                                      x2="59"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="46"
                                      x2="66"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                  </svg>
                                </div>
                                <div className="nk-help-text">
                                  <h5>Initiate KYC Process</h5>
                                  <p className="text-soft">
                                    Register your customer with KYC to complete
                                    their profile. Click on "Initiate KYC"
                                    button. An email with KYC registration link
                                    will be sent to the customer.
                                  </p>
                                </div>
                                <div className="nk-help-action">
                                  {this.state.loading_button === false ? (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.initiateKyc();
                                      }}
                                      disabled={this.state.disabled}
                                      href="#"
                                      className="btn btn-lg btn-outline-primary"
                                    >
                                      <em className="icon ni ni-file-docs"></em>{" "}
                                      Initiate KYC
                                    </a>
                                  ) : null}
                                  {this.state.loading_button === true ? (
                                    <button
                                      className="btn btn-primary btn-lg"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Initiating... </span>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key !== "" ? (
                        <div id="printablediv">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    KYCs /{" "}
                                    <strong className="text-primary small">
                                      {this.state.company_title}
                                    </strong>
                                  </h3>
                                  <div className="nk-block-des text-soft">
                                    <ul className="list-inline">
                                      {/*<li>
                                        Application ID:{" "}
                                        <span className="text-base">
                                          KID000844
                                        </span>
                      </li>*/}
                                      <li>
                                        Submited At:{" "}
                                        <span className="text-base">
                                          {this.state.client_register_date}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="btn-group">
                                    {this.state.completed_percentage < 100 ? (
                                      <button
                                        onClick={() => {
                                          this.email_kyc_Details();
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                        disabled={this.state.disabled}
                                      >
                                        <em className="icon ni ni-emails"></em>
                                        <span>Send Email</span>
                                      </button>
                                    ) : null}
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.print_details();
                                      }}
                                      className="btn btn-outline-primary"
                                    >
                                      <em className="icon ni ni-printer"></em>
                                      <span>Print</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="nk-block-head">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title title">
                                  Profile Completed{" "}
                                  <span className="text-primary">
                                    ({this.state.completed_percentage}%)
                                  </span>
                                </h5>
                                <div className="card card-bordered card-preview">
                                  <div className="card-inner">
                                    <div className="progress progress-lg">
                                      <div
                                        className="progress-bar"
                                        data-progress={
                                          this.state.completed_percentage
                                        }
                                        style={{
                                          width: `${this.state.completed_percentage}%`,
                                        }}
                                      >
                                        {this.state.completed_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.kyc_details &&
                          this.state.kyc_details.length > 0 ? (
                            <div id="accordion" className="accordion">
                              {this.state.kyc_details.map((kyc, i) => (
                                <div
                                  className="accordion-item"
                                  key={`kycid${i}`}
                                >
                                  <a
                                    href="#"
                                    className={this.handle_accordion_head(i)}
                                    data-toggle="collapse"
                                    data-target={`#accordion-item-${i}`}
                                  >
                                    <h6 className="title">
                                      {kyc.steps_information.step_title}
                                      <br />
                                      <small>
                                        {kyc.steps_information.step_description}
                                      </small>
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className={this.handle_accordion_body(i)}
                                    id={`accordion-item-${i}`}
                                    data-parent="#accordion"
                                  >
                                    <div className="accordion-inner">
                                      {kyc.steps_data &&
                                      kyc.steps_data.length > 0 ? (
                                        <div className="card">
                                          <ul className="data-list is-compact">
                                            {kyc.steps_data.map(
                                              (kyc_steps, i) => (
                                                <li
                                                  className="data-item"
                                                  key={`step${i}`}
                                                >
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      {kyc_steps.field_name}
                                                    </div>
                                                    <div className="data-value">
                                                      {kyc_steps.field_type ===
                                                      "image" ? (
                                                        <img
                                                          src={
                                                            kyc_steps.field_value
                                                          }
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        />
                                                      ) : (
                                                        kyc_steps.field_value
                                                      )}
                                                    </div>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="col-lg-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Clients"
                        />
                      )}

                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Role Sub Client Assignment Modal START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalSubClientAssignRole"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-grid-alt"></em>Sub
                              Client Roles{" "}
                              <sup>
                                <small className="text-soft">
                                  {this.state.rolesSubClientName}
                                </small>
                              </sup>
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body text-left">
                          <div className="nk-modal">
                            <div className="nk-modal-text">
                              {this.state.subclientRoleLoader === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.errorMessageSubClientRole !==
                                  "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>
                                            {
                                              this.state
                                                .errorMessageSubClientRole
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.successMessageSubClientRole !==
                                  "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {
                                              this.state
                                                .successMessageSubClientRole
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="card card-bordered card-preview">
                                    <table className="table table-orders">
                                      <thead className="tb-odr-head">
                                        <tr className="tb-odr-item">
                                          <th
                                            width="35%"
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                            }}
                                          >
                                            <span className="tb-odr-action">
                                              Roles
                                            </span>
                                          </th>
                                          <th width="65%">
                                            <span className="tb-odr-action">
                                              Sub Roles
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="tb-odr-body">
                                        {this.state.subassignRoleData ? (
                                          <>
                                            {this.state.subassignRoleData.map(
                                              (role, index) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`role${index}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.subchangedRolesRights.some(
                                                            (item) =>
                                                              item.id ===
                                                                role.id &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .subchangedRolesRights
                                                          }
                                                          disabled={
                                                            role.parent_role_id ===
                                                            1
                                                          }
                                                          name={role.id.toString()}
                                                          id={`role-${role.parent_role_id}`}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={role.alt}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    {this.state.subassignSubRoleData
                                                      .filter((subRole) => {
                                                        return (
                                                          subRole.parent_role_id ===
                                                          role.parent_role_id
                                                        );
                                                      })
                                                      .map(
                                                        (subRole, subIndex) => (
                                                          <div
                                                            key={`subRole${subIndex}`}
                                                          >
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={this.state.subchangedSubRolesRights.some(
                                                                    (item) =>
                                                                      item.id ===
                                                                        subRole.id &&
                                                                      item.isChecked
                                                                  )}
                                                                  onChange={
                                                                    this
                                                                      .subchangedSubRolesRights
                                                                  }
                                                                  name={subRole.id.toString()}
                                                                  id={`subrole-${subRole.parent_role_id}`}
                                                                  color="primary"
                                                                />
                                                              }
                                                              style={{
                                                                marginBottom:
                                                                  "0px",
                                                              }}
                                                              label={
                                                                subRole.alt
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                  </td>
                                                </tr>
                                              )
                                            )}

                                            {this.state.assignSubRoleData
                                              .filter((subRole) => {
                                                // Check if parent_role_id exists in assignRoleData.parent_id
                                                return !this.state.assignRoleData.some(
                                                  (role) =>
                                                    role.parent_role_id ===
                                                    subRole.parent_role_id
                                                );
                                              })
                                              .map((subRole, subIndex) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`subRole${subIndex}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    {/* Add your checkbox logic for subRoles here */}
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.subchangedSubRolesRights.some(
                                                            (item) =>
                                                              item.id ===
                                                                subRole.id &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .subchangedSubRolesRights
                                                          }
                                                          name={subRole.id.toString()}
                                                          id={`subrole-${subRole.parent_role_id}`}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={subRole.alt}
                                                    />
                                                  </td>
                                                </tr>
                                              ))}
                                          </>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="nk-modal-action  text-right">
                                    <a
                                      href="#"
                                      className="btn btn-lg btn-mw btn-primary "
                                      onClick={() => {
                                        this.handleSubmitRoleCheckboxSub();
                                      }}
                                      disabled={this.state.subdisableRoleBtn}
                                    >
                                      Assign Roles
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Agreement MODAL End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subclients);
