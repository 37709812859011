import { React, Component } from "react";
import { addSubUser, checkResllerAccount } from "./../../../config/api_calls";
import { LOGO } from "../../../config/config";
import { ValidateEmail } from "../../../config/utility";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const auth = new HelperClass();

export default withRouter(
  class Register extends Component {
    constructor() {
      super();
      this.state = {
        comp_name: "",
        name: "",
        title: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        password: "",
        confirm_password: "",
        errorEmail: "",
        errorPass: "",
        errorcPass: "",
        errorName: "",
        errorMessage: "",
        errorCompName: "",
        errorTitle: "",
        disabled: false,
        successMessage: "",
        confirm_password: "",
        errorConfirm: "",
        errorPhone: "",
        tableLoader: true,
        pageError: "",
        step: 1,
        passwordShown: false,
        confirmPasswordShown: false,
      };
      this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
      this.captcha_token = null;
    }

    async componentDidMount() {
      let signup_hash = this.props.match.params.signup_hash;

      if (signup_hash !== "") {
        try {
          this.setState({ tableLoader: true });

          let response = await checkResllerAccount({
            signup_hash: signup_hash,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          });

          if (response.data.status === 200) {
            console.log("Signup hash is valid");
          } else if (response.data.status === 404) {
            console.log("Signup hash not found");
            this.setState({
              pageError:
                "Signup hash not found. Please enter a valid URL or contact admin for more details.",
            });
          } else {
            console.log("Error checking signup hash:", response.data.status);
          }
        } catch (error) {
          console.error("Error checking signup hash:", error);
          this.setState({
            pageError:
              "There is some error. Please try again or contact support.",
          });
        } finally {
          this.setState({ tableLoader: false });
        }
      } else {
        console.log("Signup hash is empty");
      }
    }

    addMenuHistory = (link) => {
      this.props.props.history.push(link);
    };

    phoneUtil = PhoneNumberUtil.getInstance();

    isPhoneValid = (phone) => {
      try {
        return this.phoneUtil.isValidNumber(
          this.phoneUtil.parseAndKeepRawInput(phone)
        );
      } catch (error) {
        return false;
      }
    };

    handleChange = async (event) => {
      const { target } = event;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const { name } = target;

      this.setState({
        [name]: value,
      });
      //console.log("handleChange: ", name + " " + value);
    };

    togglePassword = () => {
      this.setState({
        passwordShown: !this.state.passwordShown,
      });
    };

    toggleConfirmPassword = () => {
      this.setState({
        confirmPasswordShown: !this.state.confirmPasswordShown,
      });
    };

    checkNumber = async (evt) => {
      var theEvent = evt || window.event;
      var key = theEvent.keyCode || theEvent.which;
      var keyCode = key;
      key = String.fromCharCode(key);
      if (key.length == 0) return;
      var regex = /^[0-9.\b]+$/;
      if (keyCode == 188 || keyCode == 190) {
        return;
      } else {
        if (!regex.test(key)) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
        }
      }
    };

    fieldValidation = () => {
      let { comp_name, name, title, email, password, confirm_password, phone } =
        this.state;

      if (comp_name === "") {
        this.setState({
          errorCompName: true,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Company Name is required.",
        });
        return false;
      } else if (name === "") {
        this.setState({
          errorName: true,
          errorCompName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Name is required.",
        });
        return false;
      } else if (title === "" || title === null) {
        this.setState({
          errorTitle: true,
          errorCompName: false,
          errorName: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Title is required.",
        });
        return false;
      } else if (email === "") {
        this.setState({
          errorEmail: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Email is required.",
        });
        return false;
      } else if (!ValidateEmail(email)) {
        this.setState({
          errorEmail: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Invalid email format.",
        });
        return false;
      } else if (phone === "") {
        this.setState({
          errorPhone: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorMessage: "Phone is required.",
        });
        return false;
      } else if (!this.isPhoneValid(phone)) {
        this.setState({
          errorPhone: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorMessage: "Invalid phone number.",
        });
        return false;
      } else if (!password || password === "") {
        console.log("My Title", this.state.title);
        this.setState({
          errorPass: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,

          errorcPass: false,
          errorPhone: false,
          errorMessage: "Password is required.",
        });
        return false;
      } else if (!password || password.length < 8) {
        this.setState({
          errorPass: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Use 8 characters or more for your password",
        });
        return false;
      } else if (!confirm_password || confirm_password === "") {
        this.setState({
          errorcPass: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorPhone: false,
          errorMessage: "Confirm password is required.",
        });
        return false;
      } else if (!confirm_password || confirm_password.length < 8) {
        this.setState({
          errorcPass: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorPhone: false,
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        return false;
      } else if (password !== confirm_password) {
        this.setState({
          errorcPass: true,
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorPhone: false,
          errorMessage: "Password and confirm password do not match.",
        });
        return false;
      } else {
        this.setState({
          errorCompName: false,
          errorName: false,
          errorTitle: false,
          errorEmail: false,
          errorPass: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "",
          disabled: true,
        });
        return true;
      }
    };

    formSave = async () => {
      let {
        name,
        email,
        password,
        confirm_password,
        phone,
        comp_name,
        title,
        address,
        city,
        state,
        zip,
      } = this.state;

      if (this.fieldValidation() === true) {
        this.setState({
          errorMessage: "",
          disabled: true,
        });

        const saveResponce = await addSubUser(
          auth.getAccount(),
          auth.getToken(),
          name,
          email,
          password,
          phone,
          comp_name,
          title,
          address,
          city,
          state,
          zip
        );
        console.log("ADD RES: ", saveResponce.data);
        if (
          saveResponce.data.status === 403 ||
          saveResponce.data.errors === "authentication missing" ||
          saveResponce.data.errors === "jwt expired"
        ) {
          auth.logout();
        } else if (
          saveResponce.data.status === 404 &&
          saveResponce.data.message === "Account Already Exists"
        ) {
          // window.location.replace("/error");
          this.setState({
            errorMessage: "Email Already Exists",
            successMessage: "",
            disabled: false,
          });
        } else if (
          saveResponce.data.status === 200 &&
          saveResponce.data.message === "Success"
        ) {
          this.setState({
            errorMessage: "",
            successMessage: "Sub user added successfully.",
            disabled: false,
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
        } else {
          this.setState({
            errorMessage: saveResponce.data.message,
            successMessage: "",
            disabled: false,
          });
        }

        // Clear error message after 3 seconds
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    };

    render() {
      const { step } = this.state;
      const {
        comp_name,
        password,
        confirm_password,
        name,
        email,
        phone,
        title,
        address,
        city,
        state,
        zip,
        errorMessage,
        successMessage,
      } = this.state;
      const values = {
        comp_name,
        password,
        confirm_password,
        name,
        email,
        phone,
        title,
        address,
        city,
        state,
        zip,
        errorMessage,
        successMessage,
      };

      return (
        <>
          <div className="nk-body npc-crypto ui-clean pg-auth">
            <div className="nk-app-root">
              <div className="nk-main ">
                <div className="nk-wrap nk-wrap-nosidebar">
                  <div className="nk-content ">
                    <div className="nk-block nk-block-middle nk-auth-body wide-md">
                      {this.state.pageError ? (
                        <div className="nk-content ">
                          <div className="nk-block nk-block-middle wide-md mx-auto">
                            <div className="nk-block-content nk-error-ld text-center">
                              <img
                                className="nk-error-gfx"
                                src="../../../assets/images/error-404.svg"
                                alt=""
                              />
                              <div className="wide-xs mx-auto">
                                <h3 className="nk-error-title">
                                  Oops! Why you’re here?
                                </h3>
                                <p className="nk-error-text">
                                  {this.state.pageError}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="brand-logo pb-4 text-center">
                            <div className="brand-logo pb-4 text-center">
                              <a className="logo-link">
                                <img
                                  className="logo-light logo-img logo-img-lg"
                                  src={LOGO}
                                  alt="logo"
                                />
                                <img
                                  className="logo-dark logo-img logo-img-lg"
                                  src={LOGO}
                                  alt="logo-dark"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="card card-bordered pt-3 ">
                            {this.state.tableLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                <div className="card-inner card-inner-lg">
                                  <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                      <h4 className="nk-block-title">
                                        Sub User SignUp
                                      </h4>
                                      <div className="nk-block-des">
                                        <p
                                          style={{
                                            color: "#637381",
                                            marginTop: "25px",
                                          }}
                                        >
                                          {" "}
                                          This portal provides you access to
                                          service contracts, rates, reports, and
                                          product marketing resources you need
                                          to establish services. Please visit
                                          the main site for other Business
                                          Units.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-gs">
                                    <div className="col-xxl-12">
                                      <div className="nk-block nk-block-lg">
                                        <div className="card card-bordered">
                                          <div className="card-inner">
                                            {this.state.errorMessage !== "" ? (
                                              <div
                                                className="example-alert"
                                                style={{ marginBottom: "30px" }}
                                              >
                                                <div className="alert alert-pro alert-danger">
                                                  <div className="alert-text">
                                                    <h4>Error</h4>
                                                    <p>
                                                      {this.state.errorMessage}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state.successMessage !==
                                            "" ? (
                                              <div
                                                className="example-alert"
                                                style={{ marginBottom: "30px" }}
                                              >
                                                <div className="alert alert-pro alert-success">
                                                  <div className="alert-text">
                                                    <h4>Success</h4>
                                                    <p>
                                                      {
                                                        this.state
                                                          .successMessage
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}

                                            <form
                                              method="post"
                                              className="pageForm"
                                              id="pageForm"
                                            >
                                              <div className="row g-4">
                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Company Name"
                                                      id="comp_name"
                                                      name="comp_name"
                                                      value={
                                                        this.state.comp_name
                                                      }
                                                      placeholder="Company name"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorCompName
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Full Name"
                                                      id="name"
                                                      name="name"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.name
                                                      }
                                                      placeholder="Full Name"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorName
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Title"
                                                      id="title"
                                                      name="title"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      placeholder="Title"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorTitle
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Email"
                                                      type="text"
                                                      error={
                                                        this.state.errorEmail
                                                      }
                                                      id="email"
                                                      name="email"
                                                      defaultValue={
                                                        this.state.email
                                                      }
                                                      inputProps={{
                                                        maxLength: 250,
                                                      }}
                                                      placeholder="Enter your email address"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                    />
                                                  </div>
                                                </div>

                                                {/* <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Phone"
                                                      type="text"
                                                      id="phone"
                                                      name="phone"
                                                      inputProps={{
                                                        maxLength: 14,
                                                      }}
                                                      defaultValue={
                                                        this.state.phone
                                                      }
                                                      placeholder="Enter your phone"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      onKeyPress={() => {
                                                        this.checkNumber();
                                                      }}
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorPhone
                                                      }
                                                    />
                                                  </div>
                                                </div> */}
                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <PhoneInput
                                                      required
                                                      defaultCountry={"us"}
                                                      inputProps={{
                                                        name: "phone",
                                                        id: "phone",
                                                        maxLength: 20,
                                                      }}
                                                      value={this.state.phone}
                                                      onChange={(value) =>
                                                        this.handleChange({
                                                          target: {
                                                            name: "phone",
                                                            value,
                                                          },
                                                        })
                                                      }
                                                      inputStyle={{
                                                        padding: "18px 8px",
                                                      }}
                                                      helperText="Enter the phone number here."
                                                      label="Phone Number"
                                                      error={
                                                        this.state.errorPhone
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Address"
                                                      id="address"
                                                      name="address"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.address
                                                      }
                                                      placeholder="Address"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="City"
                                                      id="city"
                                                      name="city"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.city
                                                      }
                                                      placeholder="City"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="State"
                                                      id="state"
                                                      name="state"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.state
                                                      }
                                                      placeholder="State"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Zip"
                                                      id="zip"
                                                      name="zip"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.zip
                                                      }
                                                      placeholder="Zip"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Password"
                                                      type={
                                                        this.state.passwordShown
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      id="password"
                                                      name="password"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state.password
                                                      }
                                                      placeholder="Enter your password"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorPass
                                                      }
                                                    />
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      tabIndex="-1"
                                                      onClick={() => {
                                                        this.togglePassword();
                                                      }}
                                                      className="form-icon form-icon-right passcode-switch"
                                                      data-target="password"
                                                    >
                                                      {this.state
                                                        .passwordShown ? (
                                                        <em className="icon ni ni-eye-off"></em>
                                                      ) : (
                                                        <em className="icon ni ni-eye"></em>
                                                      )}
                                                    </a>
                                                  </div>
                                                </div>

                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <MutextField
                                                      label="Confirm Password"
                                                      type={
                                                        this.state
                                                          .confirmPasswordShown
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      id="confirm_password"
                                                      name="confirm_password"
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                      defaultValue={
                                                        this.state
                                                          .confirm_password
                                                      }
                                                      placeholder="Confirm your password"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      error={
                                                        this.state.errorcPass
                                                      }
                                                    />
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      tabIndex="-1"
                                                      onClick={() => {
                                                        this.toggleConfirmPassword();
                                                      }}
                                                      className="form-icon form-icon-right passcode-switch"
                                                      data-target="password"
                                                    >
                                                      {this.state
                                                        .confirmPasswordShown ? (
                                                        <em className="icon ni ni-eye-off"></em>
                                                      ) : (
                                                        <em className="icon ni ni-eye"></em>
                                                      )}
                                                    </a>
                                                  </div>
                                                </div>

                                                <div className="col-lg-4"></div>
                                                <div
                                                  style={{ clear: "both" }}
                                                ></div>

                                                <div className="col-lg-12">
                                                  <div className="form-group text-right">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        this.formSave()
                                                      }
                                                      className="btn btn-lg btn-primary"
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="container wide-lg mt-3">
                            <div className="row g-3">
                              <div className="col-lg-12">
                                <div className="nk-block-content text-center">
                                  <p>
                                    &copy; {new Date().getFullYear()}{" "}
                                    <a
                                      href="https://onboardsoft.com/"
                                      style={{ color: "#526484" }}
                                    >
                                      OnBoardsoft LLC
                                    </a>
                                    . All Rights Reserved.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
);
