import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  COMPANY_NAME,
  KYC_URL,
  APP_URL,
  AGENT_PORTAL_URL,
} from "../../../config/config";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail } from "../../../config/utility";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";
import { withRouter } from "react-router-dom";

import {
  listClientAllowedServices,
  getClientSettings,
  getAgreementPdf,
  getClientInfo,
  updateClientAllowedServices,
} from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { Editor } from "@tinymce/tinymce-react";
import TextField from "../../../components/InputFields/TextField";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
} from "./../../../config/config";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import countryList from "react-select-country-list";

const animatedComponents = makeAnimated();
const auth = new HelperClass();
class ViewClient extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      formLoader: true,
      signupLoader: true,
      sharelinkLoader: false,
      showServiceLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      teamsArray: [],
      tfnTermArray: [],
      azTermArray: [],
      cnamArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      cnam_service: "",
      liveagent_service: "",

      loginErrorMessage: "",
      login_url: "",

      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      showSendEmail: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      emptyService: 0,
      all_client_data: [],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      showKycApproved: false,
      showApprovedAction: "",
      showApprovedAction: "",
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      client_accountno: "",
      kyc_email_address: "",
      kyc_approved: "",
      credit_applications: [],
      processing_request: false,
      creditErrorMessage: "",
      creditSuccessMessage: "",
      application_no: "",
      rejection_container: false,
      rejection_reason: "",
      select_agent: "",
      client_email: "",
      client_name: "",
      clientErrorMessage: "",
      clientSuccessMessage: "",
      selected_agent: null,
      selected_agent_accountno: "",
      all_active_agents: [],
      all_sharelinks: [],
      sharelinkErrorMessage: "",
      client_comp_name: "",
      client_update_name: "",
      client_title: "",
      client_update_email: "",
      client_phone: "",
      client_address: "",
      client_country: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_password: "",
      client_confirm_password: "",
      errorClientTitle: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientPass: "",
      errorClientAddress: "",
      errorClientCountry: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientMessage: "",
      errorClientMessageEdit: "",
      successClientMessage: "",
      successClientMessageEdit: "",
      isApproveActive: false,
      approve_client_id: "",
      approve_agreement_accountno: "",

      selected_products: [],
      checkAll: false,
      update_service_button: false,
      assignedErrorMessage: "",
      assignedSuccessMessage: "",
      assignedLoader: true,
    };
    this.countryOptions = countryList().getData();
  }
  async componentDidMount() {
    let tab_type = this.props.props.match.params.tab_type;
    let client_accountno = this.props.props.match.params.client_accountno;
    this.setState({
      client_accountno: client_accountno,
    });
    const getClientInfoResponce = await getClientInfo(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      this.setState({
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
      });
    } else {
      // window.location.replace("/error");
    }
    this.showAssignedServices();
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  clientServices = async (value, tableMeta) => {
    let company_title = tableMeta.rowData[1];
    let client_id = tableMeta.rowData[0];
    const servicesResponce = await getClientSettings(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    //console.log("getClientSettings: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        client_services_welcome_content: servicesResponce.data.data,
        client_services_email: "",
        errorMessage: "",
        successMessage: "",
        company_title: company_title,
        client_services_client_id: client_id,
      });
    }

    window.$("#modalFormClientServices").modal("show");
  };

  showAssignedServices = async () => {
    const { client_accountno } = this.state;
    const servicesResponce = await listClientAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("showAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        showServiceLoader: false,
        assignedLoader: false,
      });
    } else {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
    }
  };

  handleCheckboxChange = (id) => {
    const serviceAssignedData = this.state.serviceAssignedData.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    this.setState({ serviceAssignedData });
  };

  handleCheckAllChange = () => {
    const { checkAll, serviceAssignedData } = this.state;
    const updatedCheckboxes = serviceAssignedData.map((checkbox) => ({
      ...checkbox,
      checked: !checkAll,
    }));
    this.setState({
      serviceAssignedData: updatedCheckboxes,
      checkAll: !checkAll,
    });
  };

  updateAssignedServices = async () => {
    const { checkAll, serviceAssignedData, client_accountno } = this.state;
    this.setState({
      update_service_button: true,
    });
    console.log(
      "updateAssignedServices serviceAssignedData: ",
      serviceAssignedData
    );
    const servicesResponce = await updateClientAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      JSON.stringify(serviceAssignedData)
    );
    console.log("updateAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignedErrorMessage: "",
        assignedSuccessMessage:
          "Services / Products assigned successfully to the client.",
        update_service_button: false,
      });
    } else {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
    }
    setTimeout(() => {
      this.setState({
        update_service_button: false,
        assignedErrorMessage: "",
        assignedSuccessMessage: "",
      });
    }, 4000);
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handleChangeClientServices = async (event) => {
    const { target } = event;
    // console.log('handleChangeClientServicesWelcomeContent',target);
    // return;
    const value = target.value;
    const { name } = target;
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handle_accordion_head = (number) => {
    if (number === 0) {
      return "accordion-head";
    } else {
      return "accordion-head collapsed";
    }
  };

  handle_accordion_body = (number) => {
    if (number === 0) {
      return "accordion-body collapse show";
    } else {
      return "accordion-body collapse";
    }
  };

  downloadPdf = async (d_f_name, agreement_title) => {
    const reportsData = await getAgreementPdf(
      auth.getAccount(),
      auth.getToken(),
      d_f_name
    );
    //console.log("PDF.data: ", reportsData.data.data + " " + d_f_name);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      let file_name = agreement_title.replace(/[^a-zA-Z0-9]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  print_details = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  showApprovedCont = (kyc_action, accountno) => {
    this.setState({
      showKycApproved: true,
      showApprovedAction: kyc_action,
    });
  };

  addMenuHistoryLeft = (link) => {
    this.props.history.push(link);
  };

  render() {
    return (
      <div className="nk-content " id="Clients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Users /{" "}
                      <strong class="text-primary small">
                        {this.state.client_update_name} (
                        {this.state.client_comp_name})
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          User ID:{" "}
                          <span class="text-base">
                            {this.state.client_accountno}
                          </span>
                        </li>
                        {/*<li>
                          Last Login:{" "}
                          <span class="text-base">15 Feb, 2019 01:02 PM</span>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistoryLeft("/clients")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistoryLeft("/clients")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a class="nav-link active" href="#tabItemProducts">
                            <em class="icon ni ni-file-text"></em>
                            <span>Assigned Products</span>
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content">
                        <div class="tab-pane active" id="tabItemProducts">
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title">
                                  Assigned Products & Services
                                </h5>
                                <p>
                                  This list comprises the products or services
                                  allocated or permitted for this client.
                                </p>
                              </div>
                              {this.state.assignedErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.assignedErrorMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.assignedSuccessMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedLoader === true ? (
                                FormLoader()
                              ) : (
                                <div class="table-responsive">
                                  {this.state.serviceAssignedData &&
                                  this.state.serviceAssignedData.length > 0 ? (
                                    <>
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={this.state.checkAll}
                                                  class="custom-control-input"
                                                  name="selected_products_1"
                                                  onChange={
                                                    this.handleCheckAllChange
                                                  }
                                                  id="product_ids"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  htmlFor="product_ids"
                                                ></label>
                                              </div>
                                            </th>
                                            <th>Item</th>
                                            <th>SKU</th>
                                            <th>Title</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.serviceAssignedData.map(
                                            (row, index) => (
                                              <tr key={`prokey${index}`}>
                                                <td>
                                                  <div class="custom-control custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      checked={row.checked}
                                                      class="custom-control-input"
                                                      name="selected_products"
                                                      onChange={() =>
                                                        this.handleCheckboxChange(
                                                          row.id
                                                        )
                                                      }
                                                      value={row.id}
                                                      id={`product_id_${index}`}
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      htmlFor={`product_id_${index}`}
                                                    ></label>
                                                  </div>
                                                </td>
                                                <td>{row.id}</td>
                                                <td>
                                                  {row.sku && row.sku !== ""
                                                    ? row.sku
                                                    : "---"}
                                                </td>
                                                <td>{row.service_title}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                      <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                        <div class="nk-block-head-content">
                                          <ul class="nk-block-tools gx-3">
                                            {this.state
                                              .update_service_button ===
                                            true ? (
                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                disabled
                                              >
                                                <span
                                                  className="spinner-grow spinner-grow-sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                                <span> Processing... </span>
                                              </button>
                                            ) : (
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    this.updateAssignedServices()
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                  }}
                                                  class="btn btn-primary"
                                                >
                                                  <em class="icon ni ni-check-round-cut"></em>{" "}
                                                  <span>Update</span>{" "}
                                                </a>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>Oops</h6>
                                        <p>
                                          No records found.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-avatar lg bg-primary">
                              <span>CL</span>
                            </div>
                            <div class="user-info">
                              <div class="badge badge-outline-light badge-pill ucap">
                                Client
                              </div>
                              <h5>{this.state.client_update_name}</h5>
                              <span class="sub-text">
                                {this.state.client_update_email}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner card-inner-sm">
                          <ul class="btn-toolbar justify-center gx-1">
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-shield-off"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-mail"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-download-cloud"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-bookmark"></em>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                class="btn btn-trigger btn-icon text-danger"
                              >
                                <em class="icon ni ni-na"></em>
                              </a>
                            </li>
                          </ul>
                                              </div>*/}
                        <div class="card-inner">
                          <div class="overline-title-alt mb-2">Payments</div>
                          <div class="profile-balance">
                            <div class="profile-balance-group gx-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    0.00{" "}
                                    <small class="currency currency-usd">
                                      USD
                                    </small>
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Paid Amount
                                </div>
                              </div>
                              <div class="profile-balance-sub">
                                <span class="profile-balance-plus text-soft">
                                  <em class="icon ni ni-plus"></em>
                                </span>
                                <div class="profile-balance-amount">
                                  <div class="number">0.00</div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Remaining Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <div class="overline-title-alt mb-2">Order</div>
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">0</span>
                                <span class="sub-text">Total Orders</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">0</span>
                                <span class="sub-text">Completed</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">0</span>
                                <span class="sub-text">Pending</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">User ID:</span>
                              <span>UD003054</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Last Login:</span>
                              <span>15 Feb, 2019 01:02 PM</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">KYC Status:</span>
                              <span class="lead-text text-success">
                                Approved
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Register At:</span>
                              <span>Nov 24, 2019</span>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-3">Groups</h6>
                          <ul class="g-1">
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                investor
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                support
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                another tag
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewClient));
